
import { defineComponent, computed } from "vue";
import { formatCurrency } from "../../core/filters";

type Props = {
  value: number | null;
};

export default defineComponent({
  props: {
    value: {
      type: Number as () => number | null,
      default: null,
    },
  },
  setup(props: Props) {
    const formatedValue = computed(() =>
      props.value == null ? null : formatCurrency(props.value)
    );
    return {
      formatedValue,
    };
  },
});
