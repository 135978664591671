<template>
  <a-select
    v-bind="inputAttrs"
    :value="currentValue"
    :style="style"
    :loading="loading"
    :mode="uiMode"
    :allowClear="allowClear"
    @update:value="handleUpdate"
  >
    <a-select-option
      :value="item[idKey]"
      :key="item[idKey]"
      v-for="item in options"
      >{{ item[labelKey] }}</a-select-option
    >
  </a-select>
</template>

<script lang="ts">
import { Attr, VariableDef } from "@/generic";
import { useGetAllResources } from "@/generic/modules";

import { defineComponent, SetupContext, computed, unref, PropType } from "vue";

type Props = {
  typeName: string;
  fields: string[];
  arguments: VariableDef[] | undefined;
  filter: (_item: any) => boolean | null | undefined;
  idKey: string;
  labelKey: string;
  inputMode: "model" | "key";
  mode: string;
  sortAttr: string;
  value: any;
  allowClear: boolean;
};
// VariableDef[] | Ref<VariableDef[]> | ComputedRef<VariableDef[]>
export default defineComponent({
  components: {},
  props: {
    typeName: {
      type: String,
      required: true,
    },
    fields: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    arguments: {
      type: Array as PropType<VariableDef[]>,
      required: false,
      default: null,
    },
    filter: {
      type: Function as PropType<(_item: any) => boolean | null | undefined>,
      required: false,
      default: null,
    },
    idKey: {
      type: String,
      required: false,
      default: "id",
    },
    labelKey: {
      type: String,
      required: false,
      default: "name",
    },
    inputMode: {
      type: String as PropType<"model" | "key">,
      required: false,
      default: "model",
    },
    mode: {
      type: String,
      required: false,
      default: "select",
    },
    sortAttr: {
      type: String,
      required: false,
      default: "id",
    },
    value: {
      type: [Object, String] as PropType<any>,
      required: false,
      default: null,
    },
    allowClear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    const { emit } = context;

    const resourceAttrs = computed((): Attr[] => {
      const attrs: Attr[] = [
        {
          key: props.idKey,
          type: "id",
          required: true,
        },
        {
          key: props.labelKey,
          type: "string",
          required: true,
        },
      ];

      if (props.fields != null) {
        props.fields.forEach((field: string) => {
          attrs.push({ key: field, type: "string" });
        });
      }
      return attrs;
    });

    const { resources, loading } = useGetAllResources(
      props.typeName,
      resourceAttrs,
      props.arguments
    );

    const inputAttrs = computed(() =>
      Object.assign({}, context.attrs, {
        value: context.attrs.value,
      })
    );

    const currentValue = computed((): string | string[] | undefined => {
      if (props.mode === "select") {
        if (props.value) {
          return props.value[props.idKey];
        } else {
          return undefined;
        }
      } else {
        if (props.value != null) {
          return props.value.map((v: any) => v[props.idKey]);
        } else {
          return undefined;
        }
      }
    });

    const uiMode = computed(() => {
      if (props.mode === "select") {
        return undefined;
      } else {
        return props.mode;
      }
    });

    const filteredItems = computed(() => {
      let items: any = Object.assign([], unref(resources));
      if (resources != null && props.filter != null) {
        items = items.filter((item: any) => props.filter(item));
      }

      items.sort((a: any, b: any) => a[props.sortAttr] - b[props.sortAttr]);

      return items;
    });

    const options = computed(() => {
      return unref(filteredItems);
    });

    const handleUpdate = (key: string | string[]) => {
      if (key == null) {
        // 未選択
        emit("update:value", null);
        return null;
      } else if (props.mode === "select") {
        // 単数
        const result: any = unref(resources).find(
          (ele: any) => ele[props.idKey] === key
        );
        const value =
          props.inputMode === "model" ? result : result[props.idKey];
        emit("update:value", value);
        return result;
      } else {
        // 複数
        const results: any = unref(resources).filter((ele: any) =>
          key.includes(ele[props.idKey])
        );
        const values = results.map((result: any) =>
          props.inputMode === "model" ? result : result[props.idKey]
        );

        emit("update:value", values);
        return results;
      }
    };

    const style = computed((): any => {
      return {
        minWidth: "80px",
      };
    });

    return {
      inputAttrs,
      uiMode,
      currentValue,
      style,
      loading,
      handleUpdate,
      options,
    };
  },
});
</script>
