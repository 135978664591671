<style lang="less" scoped>
.number {
  text-align: right;
  width: 100%;
  min-width: 60px;
}
</style>

<template>
  <span class="number">{{ formatedValue }}{{ unit }}</span>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { formatComma } from "../../core/filters";

type Props = {
  value: number;
  unit: string;
};

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props: Props) {
    const formatedValue = computed(() => {
      return formatComma(props.value);
    });

    return {
      formatedValue,
    };
  },
});
</script>
