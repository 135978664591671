
import { defineComponent, SetupContext } from "vue";

export default defineComponent({
  emits: ["clearAuthToken"],
  setup(_, { emit }: SetupContext) {
    const reLogin = () => {
      emit("clearAuthToken");
      // ログイン画面で、StatusCheckerがキャシュされてしまう問題の応急処置
      window.location.reload();
    };
    return {
      reLogin,
    };
  },
});
