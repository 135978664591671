import { GraphQLError } from "graphql";
import { camelize } from "inflected";
export type ErrorMessages = { [key: string]: string };

export type ErrorDetail = {
  attribute: string;
  message: string;
  errors: any;
};
export type ErrorDetails = ErrorDetail[];
export type ErrorExtensions = {
  code: string;
  details: ErrorDetails;
};

function parseDetail(err: any): ErrorExtensions[] {
  if (!("graphQLErrors" in err) || err.graphQLErrors.length === 0) {
    console.log("error");
    throw err;
  }

  // validation error
  const extensionList: ErrorExtensions[] = [];
  err.graphQLErrors.forEach((graphQLError: GraphQLError) => {
    if (graphQLError.extensions === undefined) {
      throw err;
    }
    const extensions: ErrorExtensions =
      graphQLError.extensions as ErrorExtensions;
    if (!("details" in extensions)) {
      throw err;
    }
    extensionList.push(extensions);
  });
  return extensionList;
}

export function parseError(err: any): string[] {
  const extensionList: ErrorExtensions[] = parseDetail(err);

  const messages: string[] = [];
  extensionList.forEach((extensions: ErrorExtensions) => {
    extensions.details.forEach((detail: ErrorDetail) => {
      messages.push(detail.message);
    });
  });
  return messages;
}

export function parseErrorByAttr(err: any): ErrorMessages {
  const extensionList: ErrorExtensions[] = parseDetail(err);

  const messages: { [key: string]: string } = {};
  extensionList.forEach((extensions: ErrorExtensions) => {
    extensions.details.forEach((detail: ErrorDetail) => {
      const key = camelize(detail.attribute, false);
      if (messages[key] === undefined) {
        messages[key] = detail.message;
      }
    });
  });
  return messages;
}
