<style lang="less" scoped></style>

<template>
  <a-button type="link" @click="copyToClipboard()"><CopyOutlined /></a-button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CopyOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

export type Props = {
  text: string;
};

export default defineComponent({
  components: {
    CopyOutlined,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.text);
      message.success("クリップボードにコピーしました。");
    };

    return {
      copyToClipboard,
    };
  },
});
</script>
