import gql from "graphql-tag";
import { useApolloClient } from "@vue/apollo-composable";
import { Ref, toRefs, reactive } from "vue";
import { ErrorMessages } from "../core/errors";

export function useImportResource(resourceName: string | Ref<string>) {
  const { client } = useApolloClient();

  const state = reactive({
    errorMessages: {} as ErrorMessages,
  });

  const importData = async (
    file: any,
    fileFormat = "csv"
  ): Promise<boolean> => {
    state.errorMessages = {} as ErrorMessages;
    try {
      const result = await client.query({
        query: gql`
          query GetDownloadPath(
            $fileFormat: DownloadFileFormat!
            $resourceName: String!
          ) {
            importEndpoint(
              resourceName: $resourceName
              fileFormat: $fileFormat
            ) {
              endpoint
            }
          }
        `,
        variables: {
          fileFormat,
          resourceName,
        },
        fetchPolicy: "no-cache",
      });

      const importEndpoint = result.data.importEndpoint.endpoint;

      const formData = new FormData();
      formData.append("file", file);

      const importResult = await fetch(importEndpoint, {
        method: "POST",
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
        body: formData,
      });

      if (importResult.status === 200) {
        return true;
      } else {
        // state.errorMessages = { err: importResult.statusText };
        return false;
      }
    } catch (err) {
      console.log("err", err);
      state.errorMessages = err.response?.data?.error?.message || err.message;
      return false;
    }
  };

  return {
    ...toRefs(state),
    importData,
  };
}
