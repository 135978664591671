
import { defineComponent, PropType, computed, reactive } from "vue";
import { PaperClipOutlined } from "@ant-design/icons-vue";
import { OrderProductFile } from "@/client/schema";

type Props = {
  files: OrderProductFile[];
};

export default defineComponent({
  components: {
    PaperClipOutlined,
  },
  props: {
    files: {
      type: Array as PropType<OrderProductFile[]>,
      required: true,
    },
  },
  setup(props: Props) {
    const state = reactive({
      visibleMoreButton: true,
    });

    const handleMore = () => {
      state.visibleMoreButton = false;
    };

    const handleClose = () => {
      state.visibleMoreButton = true;
    };

    const viewAll = computed(
      () => props.files.length <= 5 || !state.visibleMoreButton
    );

    return {
      handleMore,
      handleClose,
      viewAll,
    };
  },
});
