<style lang="less" scoped>
.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<template>
  <div class="loading">メンテナンス中</div>
</template>
