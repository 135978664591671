
import { defineComponent, computed } from "vue";
import { formatComma, parseComma } from "../../core/filters";

type Props = {
  align: "left" | "right" | string;
  onlyInteger: boolean;
};

export default defineComponent({
  inheritAttrs: false,
  props: {
    align: {
      type: String,
      default: "right",
    },
    onlyInteger: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: Props) {
    let endsWithCommna = false;

    const formatter = (value: number | string): string => {
      const formated = formatComma(value);
      if (endsWithCommna) {
        return `${formated}.`;
      } else {
        if (props.onlyInteger) {
          return formated;
        } else {
          return value.toString();
        }
      }
    };

    // 0.01などの入力ができなくなるため、オプションから削除
    const parser = (value: string): number => {
      endsWithCommna = props.onlyInteger ? false : value.endsWith(".");
      return parseComma(value);
    };

    const alignClassName = computed(() => `input-${props.align}`);

    return {
      alignClassName,
      formatter,
      parser,
    };
  },
});
