import gql from "graphql-tag";
import { useMutation } from "@vue/apollo-composable";
import { Ref, unref, computed, toRefs, reactive } from "vue";
import { camelize, classify, singularize } from "inflected";
import { ErrorMessages, parseErrorByAttr } from "../core/errors";
import { Resource, Variables } from "../core/resource";
import { Attr } from "../core/attrs";

/**
 *
 * @param resourceName 対象の名前
 * @param resourceAttrs 属性の配列
 * @param extendVariableDefs
 * @returns
 */
export function useRemoveResource(
  resourceName: string | Ref<string>,
  resourceAttrs: Attr[] | Ref<Attr[]>
) {
  const state = reactive({
    errorMessages: {} as ErrorMessages,
  });

  const operationName = `RemoveOne${classify(unref(resourceName))}`;
  const mutaionName = `removeOne${camelize(singularize(unref(resourceName)))}`;
  const mutaionInputName = `${camelize(mutaionName)}Input`;
  const queryName = camelize(singularize(unref(resourceName)), false);

  const removeMutaiton = computed((): any => {
    const fieldString = unref(resourceAttrs)
      .map((attr: Attr) => {
        if (attr.type === "model") {
          return `${attr.key} {
                  id
                  name
                }`;
        } else {
          return attr.key;
        }
      })
      .join(" ");

    return gql`
      mutation ${operationName}($input: ${mutaionInputName}!) {
        ${mutaionName}(input: $input ) {
            ${queryName} { ${fieldString} }
        }
      }
    `;
  });

  const { mutate } = useMutation(removeMutaiton);

  const remove = async (resourceRef: Resource): Promise<boolean> => {
    const resource = unref(resourceRef);

    const input: Variables = { id: resource.id };

    try {
      await mutate({ input });
      return true;
    } catch (err) {
      state.errorMessages = parseErrorByAttr(err);
      return false;
    }
  };

  return {
    ...toRefs(state),
    remove,
  };
}
