<style scoped lang="less">
.update-message {
  width: 100vw;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #aaa;

  a {
    margin: 0 1em;
  }
}
</style>

<template>
  <div>
    <div v-if="state === 'update'" class="update-message">
      アプリケーションが更新されました。更新を行ってください。<a
        @click="reInstall"
        >更新</a
      >
    </div>
    <template v-if="state === 'loading'">
      <Loading />
    </template>
    <template v-else-if="state === 'maintenance'">
      <Maintenance />
    </template>
    <template v-else-if="state === 'relogin'">
      <slot name="relogin">
        <Maintenance />
      </slot>
    </template>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide } from "vue";
import { usePwa, UsePwaKey } from "@/shared/modules/pwa";
import Loading from "./Loading.vue";
import Maintenance from "./Maintenance.vue";

export default defineComponent({
  components: {
    Loading,
    Maintenance,
  },
  props: {
    msg: String,
  },
  setup() {
    const pwa = usePwa();
    provide(UsePwaKey, pwa);
    return {
      ...pwa,
    };
  },
});
</script>
