import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import "dayjs/locale/ja";

export const toPercent = (value: number | string): number => {
  return new BigNumber(value).multipliedBy(100).toNumber();
};

export const formatComma = function (value: number | string): string {
  const numberValue = Number(value);
  if (isNaN(numberValue)) return "0";
  return numberValue.toLocaleString();
};

export const formatPercent = function (value: number | string): string {
  const parsentValue = toPercent(value);
  if (isNaN(parsentValue)) return "0%";
  return parsentValue.toString() + "%";
};

export const formatCurrency = function (value: number | string): string {
  return Number(value).toLocaleString() + "円";
};

export const parseComma = function (value: string): number {
  const parsed = Number(value.replace(/[^-0-9\\.]/g, ""));
  if (isNaN(parsed)) return 0;
  return parsed;
};

export const parseCurrency = function (value: string): number {
  return parseComma(value);
};

export const parsePercent = function (value: string): number {
  return new BigNumber(parseComma(value)).dividedBy(100).toNumber();
};

export const formatDate = function (value: any): string | null {
  if (value == null) return null;
  return dayjs(value).locale("ja").format("YY.MM.DD");
};

export const formatTimestamp = function (value: any): string | null {
  if (value == null) return null;
  return dayjs(value).locale("ja").format("YY.MM.DD HH:mm:ss");
};

export function hankaku2Zenkaku(str: string): string {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: string) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
}
