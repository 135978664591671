
import { defineComponent, SetupContext, reactive, toRefs, computed } from "vue";

type Props = {
  collapsible: boolean;
  defaultCollapsed: boolean;
  collapsedWidth: number;
  collapsed: boolean;
  sidebarStyle: Record<string, any>;
  headerStyle: Record<string, any>;
  contentStyle: Record<string, any>;
  footerStyle: Record<string, any>;
};

export default defineComponent({
  components: {},
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: true,
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    defaultCollapsed: {
      type: Boolean,
      default: true,
    },
    collapsedWidth: {
      type: Number,
      default: 80,
    },
    sidebarStyle: {
      type: Object,
      default: () => ({}),
    },
    headerStyle: {
      type: Object,
      default: () => ({ background: "#fff", padding: 0 }),
    },
    contentStyle: {
      type: Object,
      default: () => ({ margin: "0" }),
    },
    footerStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      selectedKeys: ["1"],
    });

    const hasSidemenu = computed(() => context.slots.sidemenu != null);
    const hasHeader = computed(() => context.slots.header != null);
    const hasFooter = computed(() => context.slots.footer != null);

    return {
      ...toRefs(state),
      hasSidemenu,
      hasHeader,
      hasFooter,
    };
  },
});
