
import { underscore } from "inflected";

import { defineComponent, SetupContext, computed, PropType } from "vue";

type Props = {
  typeDef: any;
  typeName: string;
  mode: string | undefined;
  value: any;
  filter: (item: string) => boolean | null | undefined;
};
export default defineComponent({
  props: {
    typeDef: {
      type: Object as PropType<any>,
      required: true,
    },
    typeName: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: undefined,
    },
    value: {
      type: [Object, String] as PropType<any>,
      required: false,
      default: null,
    },
    filter: {
      type: Function as PropType<(item: string) => boolean | null | undefined>,
      required: false,
      default: null,
    },
  },
  setup(props: Props, context: SetupContext) {
    const { emit } = context;

    const inputAttrs = computed(() =>
      Object.assign({}, context.attrs, {
        value: context.attrs.value,
      })
    );

    const localeGroup = computed(() => underscore(props.typeName));
    const optionTypes = computed(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      () => {
        let options = Object.entries(props.typeDef).map(
          ([_, value]) => value
        ) as string[];
        if (props.filter != null) {
          return options.filter(props.filter);
        } else {
          return options;
        }
      }
    );

    const handleUpdate = async (key: string | string[]) => {
      emit("update:value", key);
    };

    const style = computed((): any => {
      return {
        minWidth: "80px",
      };
    });

    return {
      inputAttrs,
      style,
      localeGroup,
      optionTypes,
      handleUpdate,
    };
  },
});
