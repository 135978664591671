import {} from "vue";
import {
  from,
  HttpLink,
  ApolloClient,
  NormalizedCacheObject,
} from "@apollo/client";
import { DefaultApolloClient, ApolloClients } from "@vue/apollo-composable";
import { authLink, errorLink } from "./link";
import cache from "./cache";
import { getEndpoint } from "@/shared/providors/session";

export const createApolloClient = (
  path: string,
  prefix: string | null | undefined = undefined
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: from([
      errorLink,
      authLink(prefix),
      new HttpLink({
        uri: getEndpoint(path),
      }),
    ]),
    cache,
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
};

type ClientId = string;
type ClientDict<T> = Record<ClientId, ApolloClient<T>>;
export const apolloClients = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  install: (app: any, options: ClientDict<any>) => {
    // app.config.globalProperties.$translate = key => {
    //   return key.split('.').reduce((o, i) => {
    //     if (o) return o[i]
    //   }, options)
    // }
    app.provide(ApolloClients, options);
    app.provide(DefaultApolloClient, options["default"]);
  },
};

export const defaultApolloClient = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  install: (app: any, apolloClient: ApolloClient<any>) => {
    app.provide(DefaultApolloClient, apolloClient);
  },
};
