<style lang="less" scoped>
.session-time-out {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="session-time-out">
    <div>セッションが切れました</div>
    <div>
      <a @click="reLogin">再ログイン</a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, SetupContext } from "vue";

export default defineComponent({
  emits: ["clearAuthToken"],
  setup(_, { emit }: SetupContext) {
    const reLogin = () => {
      emit("clearAuthToken");
      // ログイン画面で、StatusCheckerがキャシュされてしまう問題の応急処置
      window.location.reload();
    };
    return {
      reLogin,
    };
  },
});
</script>
