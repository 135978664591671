<style lang="less" scoped>
.number {
  text-align: right;
  width: 100%;
  min-width: 60px;
}
</style>
<template>
  <span class="number">{{ formatedValue }}</span>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { formatCurrency } from "../../core/filters";

type Props = {
  value: number | null;
};

export default defineComponent({
  props: {
    value: {
      type: Number as () => number | null,
      default: null,
    },
  },
  setup(props: Props) {
    const formatedValue = computed(() =>
      props.value == null ? null : formatCurrency(props.value)
    );
    return {
      formatedValue,
    };
  },
});
</script>
