<template>
  <a-form
    ref="formRef"
    :rules="rules"
    :model="editModel"
    :labelCol="labelCol"
    :wrapperCol="wrapperCol"
    :hideRequiredMark="!editable"
    @submit.prevent="handleSave"
  >
    <a-form-item
      :label="$ta(attr.key)"
      :key="attr.key"
      :name="attr.key"
      :validateStatus="validateStatus"
      :help="errorMessages && errorMessages[attr.key]"
      v-for="attr in fields"
    >
      <div>
        <attr-item
          v-model:value="editModel[attr.key]"
          :model="editModel"
          :attr="attr"
          :key="attr.key"
          :editable="editable && (isNew || !attr.createonly)"
        />
      </div>
    </a-form-item>

    <a-form-item :wrapper-col="buttonWrapperCol" v-if="!noAction && editable">
      <a-space>
        <a-button type="primary" @click="handleSave">{{
          isNew ? $t("actions.create") : $t("actions.update")
        }}</a-button>
        <a-button @click="handleCancel">{{ $t("actions.cancel") }}</a-button>
      </a-space>
    </a-form-item>
  </a-form>
</template>

<script lang="ts">
import {
  defineComponent,
  SetupContext,
  computed,
  ref,
  PropType,
  unref,
} from "vue";
import {
  buildRule,
  ErrorMessages,
  Attr,
  Attrs,
  GenericModel,
} from "../../core";
import { AttrItem } from "../attrs";
import { clone } from "../../core";

type Props = {
  editable: boolean;
  noAction: boolean;
  errorMessages: ErrorMessages;
  model: GenericModel;
  attrs: Attrs;
  layout: string;
};

export default defineComponent({
  components: {
    AttrItem,
  },
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    noAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Object as PropType<ErrorMessages>,
      required: false,
      default: {} as ErrorMessages,
    },
    model: {
      type: Object as PropType<GenericModel>,
      required: true,
    },
    attrs: {
      type: Object as PropType<Attrs>,
      required: true,
    },
    layout: {
      type: String,
      required: false,
      default: "horizontal",
    },
  },
  setup(props: Props, context: SetupContext) {
    const formRef = ref();

    const editModel = ref(clone(props.model));

    const validateStatus = computed((): string | undefined => {
      if (
        props.errorMessages != null &&
        Object.keys(props.errorMessages).length !== 0
      ) {
        return "error";
      } else {
        return undefined;
      }
    });

    const isNew = computed(() => {
      return props.model.id == null;
    });

    const labelCol = computed(() => {
      if (props.layout === "horizontal") {
        return { span: 8 };
      } else {
        return undefined;
      }
    });

    const wrapperCol = computed(() => {
      if (props.layout === "horizontal") {
        return { span: 16 };
      } else {
        return undefined;
      }
    });

    const buttonWrapperCol = computed(() => {
      if (props.layout === "horizontal") {
        return { offset: 6, span: 18 };
      } else {
        return undefined;
      }
    });

    const fields = computed(() => {
      return props.attrs.filter((attr: Attr) => {
        return (
          attr.key !== "id" &&
          attr.readonly != true &&
          (attr.if == null || attr.if(unref(editModel)))
        );
      });
    });

    const rules = buildRule(props.attrs);

    const clearValidate = () => {
      formRef.value?.clearValidate();
    };

    const validate = async (): Promise<boolean> => {
      clearValidate();
      return await formRef.value.validate();
    };

    const resetForm = () => {
      formRef.value.resetFields();
      //context.emit('reset');
    };

    const handleSave = async () => {
      if (!(await validate())) {
        return false;
      }

      context.emit("save", editModel.value);
    };

    const handleCancel = () => {
      resetForm();
      context.emit("cancel");
    };

    return {
      formRef,
      editModel,
      validateStatus,
      isNew,
      fields,
      labelCol,
      wrapperCol,
      buttonWrapperCol,
      rules,
      clearValidate,
      validate,
      handleSave,
      handleCancel,
    };
  },
});
</script>
