<style lang="less" scoped>
.file-list {
  .file {
    margin: 5px 5px;
  }
  .button {
    margin-top: 5px;
  }
}
</style>
<template>
  <a-row class="file-list" v-if="viewAll">
    <a-col v-for="file in files" :key="file" class="file">
      <a-button size="large" v-if="!file.disabled">
        <PaperClipOutlined />{{ file.fileName }}
      </a-button>
    </a-col>
    <a-col>
      <a-button
        v-if="files.length > 5"
        size="large"
        type="link"
        @click="handleClose"
        class="button"
      >
        先頭のみ表示
      </a-button>
    </a-col>
  </a-row>
  <a-row class="file-list" v-else>
    <a-col v-for="file in files.slice(0, 5)" :key="file" class="file">
      <a-button size="large" v-if="!file.disabled">
        <PaperClipOutlined />{{ file.fileName }}
      </a-button>
    </a-col>
    <a-col>
      <a-button size="large" type="link" @click="handleMore" class="button"
        >...全て表示</a-button
      >
    </a-col>
  </a-row>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, reactive } from "vue";
import { PaperClipOutlined } from "@ant-design/icons-vue";
import { OrderProductFile } from "@/client/schema";

type Props = {
  files: OrderProductFile[];
};

export default defineComponent({
  components: {
    PaperClipOutlined,
  },
  props: {
    files: {
      type: Array as PropType<OrderProductFile[]>,
      required: true,
    },
  },
  setup(props: Props) {
    const state = reactive({
      visibleMoreButton: true,
    });

    const handleMore = () => {
      state.visibleMoreButton = false;
    };

    const handleClose = () => {
      state.visibleMoreButton = true;
    };

    const viewAll = computed(
      () => props.files.length <= 5 || !state.visibleMoreButton
    );

    return {
      handleMore,
      handleClose,
      viewAll,
    };
  },
});
</script>
