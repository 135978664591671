import gql from "graphql-tag";
import { useQuery, useResult } from "@vue/apollo-composable";
import { Ref, unref, computed, toRefs, reactive } from "vue";
import { camelize, classify, pluralize } from "inflected";
import { Variables, VariableDef } from "../core/resource";
import { Attr } from "../core/attrs";

export function useGetAllResources(
  resourceName: string | Ref<string>,
  resourceAttrs: Attr[] | Ref<Attr[]>,
  extendVariableDefs?: VariableDef[] | Ref<VariableDef[]>,
  childrenColumnName: string | null = null,
  getTotal: boolean = false
) {
  const state = reactive({
    total: null as number | null,
  });

  const operationName = `GetMany${classify(unref(resourceName))}`;
  const queryName = camelize(pluralize(unref(resourceName)), false);
  const countQueryName = `${queryName}Count`;

  const getAllQueryArguments = computed((): { [key: string]: any } => {
    const args: { [key: string]: any } = {};
    if (extendVariableDefs != null) {
      unref(extendVariableDefs).forEach(({ key, graphQLType }: VariableDef) => {
        args[key] = graphQLType;
      });
    }
    return args;
  });

  const getAllQuery = computed((): any => {
    const queryArgs = unref(getAllQueryArguments);
    const vars = Object.keys(queryArgs).map(
      (key: string) => `$${key}: ${queryArgs[key]}`
    );
    const args = Object.keys(queryArgs).map((key: string) => `${key}: $${key}`);

    const varString = vars.length > 0 ? `(${vars.join(",")})` : "";
    const argString = vars.length > 0 ? `(${args.join(",")})` : "";

    const fieldString = unref(resourceAttrs)
      .map((attr: Attr) => {
        if (attr.type === "model") {
          return `${attr.key} {
                  id
                  name
                }`;
        } else {
          return attr.key;
        }
      })
      .join(" ");

    const childrenColumn =
      childrenColumnName != null
        ? `${childrenColumnName} { ${fieldString} }`
        : "";

    const countQuery = getTotal ? `total: ${countQueryName}${argString}` : "";
  
      return gql`query ${operationName}${varString} {
                  resources: ${queryName}${argString} {
                    ${fieldString}
                    ${childrenColumn}
                  }
                  ${countQuery}
        }`;
  });

  const variables = computed((): Variables => {
    const _variables: Variables = {};

    if (extendVariableDefs != null) {
      unref(extendVariableDefs).forEach(({ key, value }: VariableDef) => {
        _variables[key] = value;
      });
    }
    return _variables;
  });

  const { result, loading, error, refetch, onResult } = useQuery(
    getAllQuery,
    variables,
    {
      fetchPolicy: "network-only",
    }
  );

  const resources = useResult(result, null, (data) => data.resources);
  const total = useResult(result, null, (data) => data.total);

  return {
    ...toRefs(state),
    resources,
    total,
    loading,
    error,
    refetch,
    onResult,
  };
}
