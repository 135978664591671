
import {
  defineComponent,
  SetupContext,
  computed,
  unref,
  watch,
  ref,
} from "vue";
import { hankaku2Zenkaku } from "../../core/filters";

type Props = {
  value: string;
};

export default defineComponent({
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const emit = context.emit;

    const formater = (value: string): string => {
      const strValue = hankaku2Zenkaku(value as string);
      if (strValue.length < 4 || strValue[3] === "-") return value;
      return `${strValue.slice(0, 3)}-${strValue.slice(3)}`;
    };

    const parser = (value: string): string => {
      let strValue = hankaku2Zenkaku(value as string);
      if (strValue?.length > 3 && strValue[3] === "-")
        strValue = `${strValue.substring(0, 3)}${strValue.substring(4)}`;
      return strValue;
    };

    const valueRef = ref(formater(props.value));

    watch(
      () => props.value,
      () => {
        valueRef.value = formater(props.value);
      }
    );

    const inputAttrs = computed(() => {
      return Object.assign({}, context.attrs, {
        maxLength: "8",
        value: unref(valueRef),
        onInput: (event: any) => {
          emit("update:value", parser(event.target.value));
        },
      });
    });

    return {
      inputAttrs,
    };
  },
});
