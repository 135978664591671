
import { defineComponent, computed } from "vue";
import { formatPercent } from "../../core/filters";

type Props = {
  value: number;
};

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props: Props) {
    const formatedValue = computed(() => formatPercent(props.value));
    return {
      formatedValue,
    };
  },
});
