import gql from "graphql-tag";
import { useApolloClient } from "@vue/apollo-composable";
import { Ref, toRefs, reactive } from "vue";
import { ErrorMessages } from "../core/errors";

export function useExportResource(resourceName: string | Ref<string>) {
  const { client } = useApolloClient();

  const state = reactive({
    errorMessages: {} as ErrorMessages,
  });

  const exportData = async (fileFormat: string): Promise<boolean> => {
    state.errorMessages = {} as ErrorMessages;
    try {
      const result = await client.query({
        query: gql`
          query GetDownloadPath(
            $fileFormat: DownloadFileFormat!
            $resourceName: String!
          ) {
            exportEndpoint(
              resourceName: $resourceName
              fileFormat: $fileFormat
            ) {
              endpoint
            }
          }
        `,
        variables: {
          fileFormat,
          resourceName,
        },
        fetchPolicy: "no-cache",
      });

      const exportEndpoint = result.data.exportEndpoint.endpoint;

      console.log("exportEndpoint", exportEndpoint);
      location.href = exportEndpoint;

      return true;
    } catch (err) {
      console.log("err", err);
      state.errorMessages = err.response?.data?.error?.message || err.message;
      return false;
    }
  };

  return {
    ...toRefs(state),
    exportData,
  };
}
