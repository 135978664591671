import { Attr } from "./attrs";
import { Ref } from "vue";
import { singularize } from "inflected";

export type AnyRef<T> = T | Ref<T>;

export type Resource = {
  id?: number;
  viewOrder?: number;
  [key: string]: any;
};

export type Pagination = {
  current?: number;
  pageSize: number;
  total?: number;
};

export type Variables = {
  [key: string]: any;
};

export type VariableDef = {
  key: string;
  graphQLType: string;
  value?: any;
};
export type VariableDefs = VariableDef[];

export function genricTypeToType(attr: Attr): string {
  let t = null;
  switch (attr.type) {
    case "string":
      t = "String";
      break;
    case "integer":
    case "currencyInteger":
    case "percentInteger":
      t = "Int";
      break;
    case "float":
    case "currency":
    case "percent":
      t = "Float";
      break;
    case "boolean":
      t = "Boolean";
      break;
    case "id":
      t = "ID";
      break;
    case "zipCode":
      t = ""; // TODO
      break;
    case "date":
      t = ""; // TODO
      break;
    case "timestamp":
      t = ""; // TODO
      break;
    case "model":
      if (attr.mode === "multiple") {
        t = "[ID]";
      } else {
        t = "ID";
      }
      break;
    case "enum":
      if (attr.mode === "multiple") {
        t = "[String]";
      } else {
        t = "String";
      }
      break;
    case "email":
    case "text":
    default:
      t = "String";
      break;
  }
  return t;
}

export function getValueBy(resource: any, attr: Attr | string): any {
  if (typeof attr === "string") {
    return resource[attr];
  } else if (attr.type === "model") {
    if (attr.mode === "multiple") {
      const value = resource[attr.key];
      if (Array.isArray(value)) {
        return value?.map(({ id }: { id: any }) => id);
      } else {
        return null;
      }
    } else {
      const value = resource[attr.key];
      const key = "id";
      return value ? value[key] : null;
    }
  } else {
    return resource[attr.key];
  }
}

export function getInputKey(attr: Attr): any {
  if (attr.type === "model") {
    if (attr.mode === "multiple") {
      return `${singularize(attr.key)}Ids`;
    } else {
      return `${attr.key}Id`;
    }
  } else {
    return attr.key;
  }
}
