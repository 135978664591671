import { VariableDefs } from "./resource";
import { Ref } from "vue";

export type EditMode = "inline" | "drawer" | "none";
export type SortDirections = "ascend" | "descend" | null;
export type Align = "left" | "right" | "center";
export type Size = "small" | "middle" | "large" | "xlarge" | number | string;

export type QueryConfig = {
  type: "Int" | "String" | "Float" | "Boolean";
};

export type GraphQLAraguemt = { key: string; type: string };
export type GraphQLAraguemts = Array<GraphQLAraguemt>;

// Links
export type LinkCallback<T> = (_model: T) => void;
export type LinkTo = { name: string; params?: { [key: string]: any } } | string;
export type AttrLink = {
  to: LinkTo;
  idKey?: string;
  idParamName?: string;
};

export type AttrType =
  | "string"
  | "integer"
  | "float"
  | "currency"
  | "currencyInteger"
  | "percent"
  | "percentInteger"
  | "boolean"
  | "zipCode"
  | "date"
  | "datetime"
  | "timestamp"
  | "id"
  | "text"
  | "email"
  | "password"
  | "customKind"
  | "model"
  | "enum";

export type InputMode = "select" | "multiple" | "radio" | "checkbox";

export type GenericModel = Record<string, any>;

export type Attr<T = GenericModel> = {
  key: string;
  type: AttrType; // 型
  dataIndex?: string;
  title?: string; // カラム名
  titleKey?: string;
  size?: Size; // カラム幅
  value?: T; // 直接指定

  // enum model
  typeName?: string;
  typeDef?: any; // 型の定義
  mode?: InputMode;
  options?: Array<T>;
  fields?: Array<string>;
  arguments?: VariableDefs | Ref<VariableDefs>;

  // inputs
  align?: Align;
  allowClear?: boolean; // クリア
  max?: number;
  min?: number;
  len?: number;
  width?: Size; // 入力の幅
  placeholder?: (_model: T) => boolean | string;
  disabled?: (_model: T) => boolean | boolean;
  showTime?: boolean;

  readonly?: ((_model?: any) => boolean) | boolean;
  createonly?: boolean;
  required?: boolean;
  visible?: boolean;

  // Sort
  sorter?: ((_a: T, _b: T) => number | boolean) | boolean;

  // Link
  link?: AttrLink; // Linkを生成します。

  // if
  if?: (_model: T) => boolean;
};

export type Attrs<T = GenericModel> = Array<Attr<T>>;

export const getInputMode = <T>(model: T, attr: Attr<T>): InputMode => {
  return attr.mode || "select";
};

export const isVisible = <T>(model: T, attr: Attr<T>): boolean => {
  return attr.visible || false;
};

export const isRequired = <T>(model: T, attr: Attr<T>): boolean => {
  return attr.required || false;
};

export const isAllowClear = <T>(model: T, attr: Attr<T>): boolean => {
  return attr.allowClear || false;
};

export const isReadonly = <T>(model: T, attr: Attr<T>): boolean => {
  if (attr.readonly != null) {
    if (typeof attr.readonly === "boolean") {
      return attr.readonly;
    } else {
      return attr.readonly(model);
    }
  } else {
    return false;
  }
};

export const isDisabled = <T>(model: T, attr: Attr<T>): boolean => {
  if (attr.disabled != null) {
    return attr.disabled(model);
  } else {
    return false;
  }
};
