<template>
  <component :is="computedName" v-bind="attrs"></component>
</template>

<script lang="ts">
import { defineComponent, SetupContext, computed, unref } from "vue";
import {
  isDisabled,
  isRequired,
  isAllowClear,
  getInputMode,
} from "../../core/attrs";
import { getValue } from "../../core/accesor";
import {
  ModelSelect,
  // ModelRadio,
  // EnumRadio,
  EnumSelect,
  // ModelCheckbox,
  InputZipCode,
  InputCurrency,
  InputPercent,
  InputNumber,
  InputText,
} from "../entry";

type Props = {
  model: any;
  attr: any;
};

export default defineComponent({
  components: {
    ModelSelect,
    // ModelRadio,
    // ModelCheckbox,
    EnumSelect,
    // EnumRadio,
    InputZipCode,
    InputCurrency,
    InputPercent,
    InputNumber,
    InputText,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    attr: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
    },
  },
  emits: ["update:value"],
  setup(props: Props, context: SetupContext) {
    const handleUpdate = (val: any) => {
      context.emit("update:value", val);
    };

    const computedName = computed(() => {
      if (props.attr.type === "text") {
        return "a-textarea";
      } else if (props.attr.type === "email") {
        return "input-text";
      } else if (props.attr.type === "password") {
        return "input-text";
      } else if (props.attr.type === "integer") {
        return "input-number";
      } else if (props.attr.type === "float") {
        return "input-number";
      } else if (props.attr.type === "percent") {
        return "input-percent";
      } else if (props.attr.type === "percentInteger") {
        return "input-percent";
      } else if (props.attr.type === "currency") {
        return "input-currency";
      } else if (props.attr.type === "currencyInteger") {
        return "input-currency";
      } else if (props.attr.type === "boolean") {
        return "a-checkbox";
      } else if (props.attr.type === "zipCode") {
        return "input-zip-code";
      } else if (props.attr.type === "date") {
        return "a-date-picker";
      } else if (props.attr.type === "datetime") {
        return "a-date-picker";
      } else if (props.attr.type === "timestamp") {
        return "a-textarea";
      } else if (props.attr.type === "customKind") {
        throw "not impl.";
      } else if (props.attr.type === "model") {
        if (props.attr.mode === "multiple") {
          return `model-select`;
        } else {
          return `model-${getInputMode(props.model, props.attr)}`;
        }
      } else if (props.attr.type === "enum") {
        if (props.attr.mode === "multiple") {
          throw "not impl.";
        } else {
          return `enum-${getInputMode(props.model, props.attr)}`;
        }
      } else {
        // string or id
        return "input-text";
      }
    });

    const style = computed(() => ({}));

    const attrs = computed(() => {
      const attr = props.attr;
      let _attrs = {
        required: isRequired(props.model, props.attr),
        disabled: isDisabled(props.model, props.attr),
        allowClear: isAllowClear(props.model, props.attr),
        style: unref(style),
      } as any;

      if (attr.type === "text") {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
            rows: undefined,
          },
          _attrs,
          context.attrs
        );
      } else if (["email", "password"].includes(attr.type)) {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
            type: attr.type,
          },
          _attrs,
          context.attrs
        );
      } else if (
        ["integer", "percentInteger", "currencyInteger"].includes(attr.type)
      ) {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
            min: attr.min,
            max: attr.max,
          },
          _attrs,
          context.attrs
        );
      } else if (attr.type === "float") {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
            min: attr.min,
            max: attr.max,
            onlyInteger: false,
            step: 0.01,
          },
          _attrs,
          context.attrs
        );
      } else if (["integer", "percent", "currency"].includes(attr.type)) {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
            min: attr.min,
            max: attr.max,
          },
          _attrs,
          context.attrs
        );
      } else if (attr.type === "boolean") {
        return Object.assign(
          _attrs,
          {
            checked: getValue(props.model, props.attr),
            "onUpdate:checked": handleUpdate,
          },
          context.attrs
        );
      } else if (attr.type === "date") {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
            type: attr.type,
          },
          _attrs,
          context.attrs
        );
      } else if (attr.type === "datetime") {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
            type: attr.type,
            showTime: true,
          },
          _attrs,
          context.attrs
        );
      } else if (attr.type === "timestamp") {
        throw "not impl.";
      } else if (attr.type === "customKind") {
        throw "not impl.";
      } else if (["model", "enum"].includes(attr.type)) {
        if (props.attr.mode === "multiple") {
          return Object.assign(
            {
              mode: "multiple",
              typeDef: attr.typeDef,
              typeName: attr.typeName || attr.key,
              value: getValue(props.model, props.attr),
              "onUpdate:value": handleUpdate,
              arguments: attr.arguments,
            },
            _attrs,
            context.attrs
          );
        } else if (props.attr.mode === "checkbox") {
          return Object.assign(
            {
              typeDef: attr.typeDef,
              typeName: attr.typeName || attr.key,
              value: getValue(props.model, props.attr),
              //arguments: attr.arguments,
              "onUpdate:value": handleUpdate,
            },
            _attrs,
            context.attrs
          );
        } else {
          return Object.assign(
            {
              typeDef: attr.typeDef,
              typeName: attr.typeName || attr.key,
              value: getValue(props.model, props.attr),
              arguments: attr.arguments,
              "onUpdate:value": handleUpdate,
            },
            _attrs,
            context.attrs
          );
        }
      } else {
        return Object.assign(
          {
            value: getValue(props.model, props.attr),
            "onUpdate:value": handleUpdate,
          },
          _attrs,
          context.attrs
        );
      }
    });

    return {
      computedName,
      attrs,
    };
  },
});
</script>
