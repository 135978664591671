// import jwtDecode from "jwt-decode";

export class UnauthrizedError extends Error {
  constructor(e?: string) {
    super(e);
  }
}

export type TokenPayload = {
  accessToken: string;
  refreshToken: string;
};

export type JwtTokenPayload = {
  name: string;
  exp: number;
};

export function getEndpoint(path: string): string {
  return path;
}

export function setAuthTokens(
  token: TokenPayload,
  prefix: string | null = null
): void {
  const refreshTokenKey = prefix ? `${prefix}.refreshToken` : "refreshToken";

  localStorage.setItem(refreshTokenKey, token.refreshToken);
}

export function setAccessToken(
  accessToken: string,
  prefix: string | null = null
): void {
  const accessTokenKey = prefix ? `${prefix}.accessToken` : "accessToken";
  localStorage.setItem(accessTokenKey, accessToken);
}

export function getAccessToken(prefix: string | null = null): string | null {
  const key = prefix ? `${prefix}.accessToken` : "accessToken";
  return localStorage.getItem(key);
}

export function getRefreshToken(prefix: string | null = null): string | null {
  const key = prefix ? `${prefix}.refreshToken` : "refreshToken";
  return localStorage.getItem(key);
}

export function getUnixTime(): number {
  return Math.floor(new Date().getTime() / 1000);
}

// export function isAccessTokenValidOrUndefined() {
//   const accessToken = getAccessToken();
//   if (accessToken == null || typeof accessToken !== 'string') return true;
//   const now = getUnixTime();
//   // const exp = jwtDecode<JwtTokenPayload>(accessToken).exp;
//   return exp >= now;
// }
