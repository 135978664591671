
import { defineComponent, computed, unref } from "vue";
import { getText } from "../../core/accesor";
import { LinkTo } from "../../core/attrs";
import { useI18n } from "vue-i18n";

type Props = {
  model: any;
  attr: any;
};

export default defineComponent({
  props: {
    model: {
      type: Object,
      required: true,
    },
    attr: {
      type: Object,
      required: true,
    },
  },
  setup(props: Props) {
    const i18n = useI18n();

    const isLink = computed(() => unref(props.attr.link) != null);
    const isText = computed(() => unref(props.attr.type) == "text");
    const linkText = computed(() => getText(props.model, props.attr, i18n.t));

    const linkTo = computed((): LinkTo => {
      const link = props.attr.link;
      if (link == null) throw "no impl";
      else if (link.idParamName === undefined) {
        return link.to;
      } else if (typeof link.to === "string") {
        return link.to;
      } else {
        const modelParams: { [key: string]: any } = {};
        modelParams[link.idParamName] = props.model[link.idKey || "id"];
        return {
          name: link.to.name,
          params: Object.assign(link.to.params || {}, modelParams),
        };
      }
    });

    return {
      isLink,
      isText,
      linkText,
      linkTo,
    };
  },
});
