import { Attr, GenericModel } from "./attrs";
import { underscore } from "inflected";
import {
  formatPercent,
  formatTimestamp,
  formatDate,
  formatComma,
  formatCurrency,
} from "./filters";

export function getValue<T>(model: GenericModel, attr: Attr<T>): any {
  if (attr.value == null) {
    return model[attr.key];
  } else if (typeof attr.value === "function") {
    return attr.value(model);
  } else {
    return attr.value;
  }
}

export function setValue<T>(
  model: GenericModel,
  attr: Attr<T>,
  value: any
): GenericModel {
  if (attr.value != null) {
    model[attr.key] = value;
  }
  return model;
}

export function getText<T = GenericModel>(
  model: T,
  attr: Attr<T>,
  t: (_key: string) => string
): any {
  const value = getValue<T>(model, attr);

  if (attr.type === "model") {
    if (attr.mode === "multiple" || attr.mode === "checkbox") {
      if (Array.isArray(value)) {
        return value?.map(({ name }: { name: string }) => name).join(",");
      } else {
        return null;
      }
    } else {
      const key = attr.titleKey || "name";
      return value ? value[key] : null;
    }
  } else if (attr.type === "enum") {
    if (value == null) {
      return "";
    } else {
      const localeGroup = underscore(attr.typeName || attr.key);
      if (attr.mode === "multiple" || attr.mode === "checkbox") {
        return value
          .map((enumName: string) => t(`enums.${localeGroup}.${enumName}`))
          .join(",");
      } else {
        return t(`enums.${localeGroup}.${value}`);
      }
    }
  } else if (attr.type === "integer") {
    return `${formatComma(value)}`;
  } else if (attr.type === "float") {
    return `${formatComma(value)}`;
  } else if (attr.type === "currency" || attr.type === "currencyInteger") {
    return `${formatCurrency(value)}`;
  } else if (attr.type === "percent" || attr.type === "percentInteger") {
    return `${formatPercent(value)}`;
  } else if (attr.type === "date") {
    return `${formatDate(value) || ""}`;
  } else if (attr.type === "timestamp") {
    return `${formatTimestamp(value) || ""}`;
  } else if (attr.type === "boolean") {
    return value == null ? "" : value ? "○" : "✗";
  } else {
    return value;
  }
}
