<template>
  <AttrEditor
    v-if="isEdit"
    :value="value"
    @update:value="handleUpdate"
    :model="model"
    :attr="attr"
    :key="attr.key"
    style="margin: -5px 0"
  />
  <AttrViewer v-else :attr="attr" :model="model" :key="attr.key" />
</template>

<script lang="ts">
import { defineComponent, SetupContext, computed } from "vue";
import { isReadonly } from "../../core/attrs";
import AttrEditor from "./AttrEditor.vue";
import AttrViewer from "./AttrViewer.vue";

type Props = {
  editable: boolean;
  model: any;
  attr: any;
  value?: any;
};

export default defineComponent({
  components: {
    AttrEditor,
    AttrViewer,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
    attr: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      required: false,
    },
  },
  emits: ["update:value"],
  setup(props: Props, context: SetupContext) {
    const isEdit = computed(
      () => props.editable && !isReadonly<any>(props.model, props.attr)
    );

    const handleUpdate = (val: any) => {
      context.emit("update:value", val);
    };

    return {
      isEdit,
      handleUpdate,
    };
  },
});
</script>
