import gql from "graphql-tag";
import { computed, InjectionKey, unref, reactive, toRefs } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import config from "@/shared/config";

export function usePwa() {
  const enableServiceWorker = "serviceWorker" in navigator;

  const state = reactive({
    state: "loading" as "loading" | "maintenance" | "relogin" | "update" | "ok",
  });

  const { result, onResult, onError, refetch } = useQuery(
    gql`
      query GetStatus {
        status {
          status
          version
          stage
        }
      }
    `,
    null,
    {
      fetchPolicy: "network-only",
      //pollInterval: pollInterval,
    }
  );

  const status = useResult(result);
  const apiVersion = computed(() => unref(status).version);
  const stage = computed(() => unref(status).stage);

  const reload = () => {
    window.location.reload();
  };

  const unregister = async () => {
    if (enableServiceWorker) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration != null) registration?.unregister();
    }
  };

  const reInstall = () => {
    unregister();
    reload();
  };

  const recheck = async () => {
    state.state = "loading";
    await refetch();
  };

  onResult((queryResult) => {
    try {
      const status = queryResult.data.status;
      if (status.version !== config.version) {
        state.state = "update";
      } else {
        state.state = "ok";
      }
    } catch (e) {
      console.log(e);
      state.state = "relogin";
    }
  });

  onError(() => {
    if (state.state !== "relogin") {
      console.log("error");
      state.state = "maintenance";
    }
  });

  return {
    ...toRefs(state),
    reInstall,
    unregister,
    reload,
    recheck,
    status,
    apiVersion,
    stage,
  };
}

export type UsePwaStore = ReturnType<typeof usePwa>;
export const UsePwaKey: InjectionKey<UsePwaStore> = Symbol("UsePwaStore");
