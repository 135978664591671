<template>
  <span role="img" class="anticon">
    <svg
      width="1em"
      height=".9em"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.20307 0.0431196C1.00481 -0.0450024 0.77234 0.00479952 0.627577 0.16641C0.482813 0.32802 0.458794 0.56455 0.568116 0.75196L3.92115 6.50002L0.568116 12.2481C0.458794 12.4355 0.482813 12.672 0.627577 12.8336C0.77234 12.9952 1.00481 13.045 1.20307 12.9569L14.7031 6.95692C14.8836 6.87667 15 6.69761 15 6.50002C15 6.30242 14.8836 6.12336 14.7031 6.04311L1.20307 0.0431196ZM4.84552 6.10002L2.21234 1.586L13.2689 6.50002L2.21234 11.414L4.84552 6.90002H9C9.22091 6.90002 9.4 6.72094 9.4 6.50002C9.4 6.27911 9.22091 6.10002 9 6.10002H4.84552Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>
