
import { defineComponent, provide } from "vue";
import { usePwa, UsePwaKey } from "@/shared/modules/pwa";
import Loading from "./Loading.vue";
import Maintenance from "./Maintenance.vue";

export default defineComponent({
  components: {
    Loading,
    Maintenance,
  },
  props: {
    msg: String,
  },
  setup() {
    const pwa = usePwa();
    provide(UsePwaKey, pwa);
    return {
      ...pwa,
    };
  },
});
