<style lang="less" scoped></style>
<template>
  <div :style="contentStyle">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

type Props = {
  bgColor: string;
  noMargin: boolean;
  noPadding: boolean;
};

export default defineComponent({
  components: {},
  props: {
    bgColor: {
      type: String,
      default: "#fff",
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props) {
    const contentStyle = computed(() => ({
      backgroundColor: props.bgColor,
      margin: props.noMargin ? "0" : "10px",
      padding: props.noPadding ? "0" : "10px",
      height: "calc(100% - 20px)",
    }));
    return {
      contentStyle,
    };
  },
});
</script>
