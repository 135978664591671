
import { defineComponent, computed } from "vue";
import { formatComma } from "../../core/filters";

type Props = {
  value: number;
  unit: string;
};

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props: Props) {
    const formatedValue = computed(() => {
      return formatComma(props.value);
    });

    return {
      formatedValue,
    };
  },
});
