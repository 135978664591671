<style lang="less" scoped>
.input {
  width: 100%;
  /deep/ .ant-input-number-handler-wrap {
    display: none;
  }
  /deep/ .ant-input-number-input {
    text-align: right;
  }
}
</style>

<template>
  <a-input v-bind="$attrs" class="input" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  inheritAttrs: false,
});
</script>
