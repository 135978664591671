import gql from "graphql-tag";
import { useMutation } from "@vue/apollo-composable";
import { Ref, unref, computed, toRefs, reactive } from "vue";
import { camelize, classify, singularize } from "inflected";
import { ErrorMessages, parseErrorByAttr } from "../core/errors";
import {
  Resource,
  Variables,
  VariableDef,
  genricTypeToType,
  getValueBy,
  getInputKey,
} from "../core/resource";
import { Attr } from "../core/attrs";

/**
 *
 * @param resourceName 対象の名前
 * @param resourceAttrs 属性の配列
 * @param extendVariableDefs
 * @returns
 */
export function useCreateResource(
  resourceName: string | Ref<string>,
  resourceAttrs: Attr[] | Ref<Attr[]>,
  extendVariableDefs?: VariableDef[] | Ref<VariableDef[]>
) {
  const state = reactive({
    errorMessages: {} as ErrorMessages,
  });

  const operationName = `CreateOne${classify(unref(resourceName))}`;
  const mutaionName = `createOne${camelize(singularize(unref(resourceName)))}`;
  const mutaionInputName = `${camelize(mutaionName)}Input`;
  const queryName = camelize(singularize(unref(resourceName)), false);

  const attrs = computed((): {
    key: string;
    type: string;
    attr?: Attr;
    value?: any;
  }[] => {
    let _attrs = unref(resourceAttrs)
      .filter((attr) => !attr.readonly && attr.key !== "id")
      .map((attr: Attr): { key: string; type: string; attr?: Attr } => {
        return {
          key: getInputKey(attr),
          type: genricTypeToType(attr),
          attr: attr,
        };
      });

    if (extendVariableDefs != null) {
      _attrs = _attrs.concat(
        unref(extendVariableDefs).map((def: VariableDef) => ({
          key: def.key,
          type: def.graphQLType,
          value: def.value,
        }))
      );
    }
    return _attrs;
  });

  const createMutaiton = computed((): any => {
    const fieldString = unref(resourceAttrs)
      .map((attr: Attr) => {
        if (attr.type === "model") {
          return `${attr.key} {
                  id
                  name
                }`;
        } else {
          return attr.key;
        }
      })
      .join(" ");

    return gql`
      mutation ${operationName}($input: ${mutaionInputName}!) {
        ${mutaionName}(input: $input ) {
            ${queryName} { ${fieldString} }
        }
      }
    `;
  });

  const { mutate } = useMutation(createMutaiton, {});

  const create = async (_resource: Resource): Promise<boolean> => {
    const resource = unref(_resource);

    // gen variables
    const input: Variables = {};

    unref(attrs).forEach((attr: any) => {
      if (attr.value != null) {
        input[attr.key] = attr.value;
      } else {
        input[attr.key] = getValueBy(resource, attr.attr || attr.key);
      }
    });

    try {
      await mutate({ input });
      return true;
    } catch (err) {
      state.errorMessages = parseErrorByAttr(err);
      return false;
    }
  };

  return {
    ...toRefs(state),
    create,
  };
}
