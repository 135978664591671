//import { inject } from "vue";

export const SchemaKey = "schame";

// export function useSchema() {
//   const schema = inject(SchemaKey) as any;
//   const findEnum = (name: string): any => {
//     // TODO
//   };
//   return {
//     findEnum,
//   };
// }
