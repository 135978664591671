<style lang="less" scoped>
.input {
  width: 88px;
  /deep/ .ant-input-number-handler-wrap {
    display: none;
  }
  /deep/ .ant-input-number-input {
    text-align: right;
  }
}
</style>

<template>
  <a-input-number
    :parser="parser"
    :formatter="formatter"
    v-bind="$attrs"
    class="input"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatPercent, parsePercent } from "../../core/filters";

type Props = {
  onlyInteger: boolean;
};

export default defineComponent({
  inheritAttrs: false,
  props: {
    onlyInteger: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: Props) {
    let endsWithCommna = false;

    const formatter = (value: number | string): string => {
      const formated = formatPercent(value);
      if (endsWithCommna) {
        return `${formated}.`;
      } else {
        return formated;
      }
    };

    const parser = (value: string): number => {
      endsWithCommna = props.onlyInteger ? false : value.endsWith(".");
      return parsePercent(value);
    };

    return {
      formatter,
      parser,
    };
  },
});
</script>
