/* eslint-disable */
import * as schema from "@/client/schema/schema";

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type ReactiveFunction<TParam> = () => TParam;
export const ClientApplicationFlowFragmentFragmentDoc = gql`
    fragment ClientApplicationFlowFragment on ClientApplicationFlow {
  id
  name
  default
}
    `;
export const ClientApplicationFlowLevelFragmentFragmentDoc = gql`
    fragment ClientApplicationFlowLevelFragment on ClientApplicationFlowLevel {
  id
  level
  userApprovers {
    id
    name
  }
}
    `;
export const ClientApplicationFlowRouteFragmentFragmentDoc = gql`
    fragment ClientApplicationFlowRouteFragment on ClientApplicationFlowRoute {
  id
  condLowerPrice
}
    `;
export const ClientShipperFragmentFragmentDoc = gql`
    fragment ClientShipperFragment on ClientShipper {
  id
  name
  code
  zipCode
  city
  block
  building
  tel
  prefecture
  address
}
    `;
export const ClientStorageFileFragmentFragmentDoc = gql`
    fragment ClientStorageFileFragment on ClientStorageFile {
  id
  name
  note
  fileName
  fileSize
  mimeType
  originalPath
  thumbnailPath
}
    `;
export const ClientStorageSummaryFragmentFragmentDoc = gql`
    fragment ClientStorageSummaryFragment on ClientStorageSummary {
  fileSizeSummary
  maxStorageSize
  storageRatio
}
    `;
export const ProductFragmentFragmentDoc = gql`
    fragment ProductFragment on Product {
  id
  name
  note
  thumbnailPath
  originalPath
  mimeType
  productType
  priceType
  unitPrice
  unitBasicDueDate
  hasMinPrice
  minPrice
  basicDueDate
  arrivalDate
  category {
    id
    name
  }
  tags {
    id
    name
  }
}
    `;
export const ClientUserGroupProductFragmentFragmentDoc = gql`
    fragment ClientUserGroupProductFragment on ClientUserGroupProduct {
  viewOrder
  product {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;
export const ClientUserFragmentFragmentDoc = gql`
    fragment ClientUserFragment on ClientUser {
  id
  name
}
    `;
export const ClientContactFragmentFragmentDoc = gql`
    fragment ClientContactFragment on ClientContact {
  id
  name
  code
  zipCode
  city
  block
  building
  tel
  fax
  prefecture
  address
  default
}
    `;
export const ClientDeliveryFragmentFragmentDoc = gql`
    fragment ClientDeliveryFragment on ClientDelivery {
  id
  name
  code
  zipCode
  city
  block
  building
  tel
  fax
  prefecture
  address
  default
}
    `;
export const ClientUserGroupFragmentFragmentDoc = gql`
    fragment ClientUserGroupFragment on ClientUserGroup {
  id
  name
  code
  note
  products {
    ...ProductFragment
  }
  clientUserGroupProducts {
    ...ClientUserGroupProductFragment
  }
  members {
    ...ClientUserFragment
  }
  defaultContact {
    ...ClientContactFragment
  }
  defaultDelivery {
    ...ClientDeliveryFragment
  }
}
    ${ProductFragmentFragmentDoc}
${ClientUserGroupProductFragmentFragmentDoc}
${ClientUserFragmentFragmentDoc}
${ClientContactFragmentFragmentDoc}
${ClientDeliveryFragmentFragmentDoc}`;
export const HolidayFragmentFragmentDoc = gql`
    fragment HolidayFragment on Holiday {
  id
  code
  name
  note
  date
  holidayType
}
    `;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  title
  content
  publishedAt
}
    `;
export const OrderProductFileFragmentFragmentDoc = gql`
    fragment OrderProductFileFragment on OrderProductFile {
  id
  fileName
  fileSize
  mimeType
  originalPath
  thumbnailPath
  createdAt
  updatedAt
  createdBy {
    id
    name
  }
  disabled
}
    `;
export const OrderProductFragmentFragmentDoc = gql`
    fragment OrderProductFragment on OrderProduct {
  id
  name
  quantity
  price
  basicDueDate
  note
  productType
  canDownload
  productPrice {
    id
    quantity
    price
  }
  files {
    ...OrderProductFileFragment
  }
  typesettingParam {
    id
  }
  outerCartonParam {
    id
  }
  innerCartonParam {
    id
  }
}
    ${OrderProductFileFragmentFragmentDoc}`;
export const TypesettingParamFragmentFragmentDoc = gql`
    fragment TypesettingParamFragment on TypesettingParam {
  id
  orderProduct {
    ...OrderProductFragment
  }
  supplierName
  productName
  factoryProductCode
  janCode
  janCode12Digits
  taxIncludedPrice
  trackingCode
  targetAgeText
  needleDetectionPrintFlag
  copyrightPatternCode
  countryOfOrigin
  threeCircleMarkFlag
  sizeName
  typesettingParamSizes {
    id
    sizeComponentName
    sizeComponentValue
  }
  materialTableName
  typesettingParamMaterials {
    id
    materialHeading
    materialName
    materialValue
  }
  typesettingParamWarnings {
    id
    warningHeading
    warning
  }
  typesettingParamRecyclingSymbols {
    id
    recyclingSymbolCode
    texts {
      id
      recyclingSymbolText
    }
  }
}
    ${OrderProductFragmentFragmentDoc}`;
export const OuterCartonParamFragmentFragmentDoc = gql`
    fragment OuterCartonParamFragment on OuterCartonParam {
  id
  orderProduct {
    ...OrderProductFragment
  }
  poNumber
  countryOfOrigin
  productName
  janCode
  janCode12Digits
  taxIncludedPrice
  quantityPerCartonText
  supplierName
  zipCode
  prefecture
  city
  block
  building
}
    ${OrderProductFragmentFragmentDoc}`;
export const InnerCartonParamFragmentFragmentDoc = gql`
    fragment InnerCartonParamFragment on InnerCartonParam {
  id
  orderProduct {
    ...OrderProductFragment
  }
  janCode
  janCode12Digits
  productName
  quantityPerInnerCartonText
  taxIncludedPrice
}
    ${OrderProductFragmentFragmentDoc}`;
export const PriceLabelParamFragmentFragmentDoc = gql`
    fragment PriceLabelParamFragment on PriceLabelParam {
  id
  orderProduct {
    ...OrderProductFragment
  }
  janCode
  janCode12Digits
  taxIncludedPrice
}
    ${OrderProductFragmentFragmentDoc}`;
export const OrderFragmentFragmentDoc = gql`
    fragment OrderFragment on Order {
  id
  name
  orderNo
  orderStatus {
    id
    name
    viewOrder
  }
  deliveryAt
  orderedAt
  rejectedAt
  uploaded
  delivered
  janCode
  price
  shippingFee
  adjustedShippingFee
  amount
  tax
  totalAmount
  setAmount
  parent {
    id
    name
  }
  clientUserGroup {
    id
    name
  }
  clientUser {
    id
    name
  }
  orderProduct {
    id
    name
    quantity
    price
    adjustedPrice
    basicDueDate
    note
    productType
    canDownload
    productPrice {
      id
      quantity
      price
    }
    files {
      ...OrderProductFileFragment
    }
    typesettingParam {
      ...TypesettingParamFragment
    }
    outerCartonParam {
      ...OuterCartonParamFragment
    }
    innerCartonParam {
      ...InnerCartonParamFragment
    }
    priceLabelParam {
      ...PriceLabelParamFragment
    }
  }
  product {
    id
    name
    note
    productType
    priceType
    thumbnailPath
    originalPath
    mimeType
    canDownload
    prices {
      id
      quantity
      price
      basicDueDate
    }
  }
  orderDeliveries {
    id
    name
    zipCode
    city
    block
    building
    tel
    fax
    prefecture
    address
    shipper {
      id
      name
      zipCode
      city
      block
      building
      tel
      prefecture
      address
    }
    quantity
  }
  orderContacts {
    id
    name
    zipCode
    city
    block
    building
    tel
    fax
    prefecture
    address
  }
  applicationStatus
  uploadStatus
  deliveryStatus
}
    ${OrderProductFileFragmentFragmentDoc}
${TypesettingParamFragmentFragmentDoc}
${OuterCartonParamFragmentFragmentDoc}
${InnerCartonParamFragmentFragmentDoc}
${PriceLabelParamFragmentFragmentDoc}`;
export const OrderApprovalRecordFragmentFragmentDoc = gql`
    fragment OrderApprovalRecordFragment on OrderApprovalRecord {
  id
  level
  approvedAt
  approvedBy {
    id
    name
  }
  rejectedAt
  rejectedBy {
    id
    name
  }
  order {
    ...OrderFragment
  }
  parent {
    id
  }
}
    ${OrderFragmentFragmentDoc}`;
export const OrderCommentFragmentFragmentDoc = gql`
    fragment OrderCommentFragment on OrderComment {
  id
  comment
  createdAt
  username
  commentType
  files {
    id
    fileName
    fileSize
    mimeType
    originalPath
    thumbnailPath
  }
}
    `;
export const OrderCommentFileFragmentFragmentDoc = gql`
    fragment OrderCommentFileFragment on OrderCommentFile {
  id
  fileName
  fileSize
  mimeType
  originalPath
  thumbnailPath
}
    `;
export const OrderContactFragmentFragmentDoc = gql`
    fragment OrderContactFragment on OrderContact {
  id
  name
  zipCode
  city
  block
  building
  tel
  fax
  prefecture
  address
}
    `;
export const OrderDeliveryFragmentFragmentDoc = gql`
    fragment OrderDeliveryFragment on OrderDelivery {
  id
  name
  zipCode
  city
  block
  building
  tel
  fax
  prefecture
  address
  quantity
  shipper {
    id
    name
    zipCode
    city
    block
    building
    tel
    prefecture
    address
  }
}
    `;
export const OrderDeliveryShipperFragmentFragmentDoc = gql`
    fragment OrderDeliveryShipperFragment on OrderDeliveryShipper {
  id
  name
  zipCode
  prefecture
  city
  block
  building
  tel
  address
}
    `;
export const OrderStatusFragmentFragmentDoc = gql`
    fragment OrderStatusFragment on OrderStatus {
  id
  name
}
    `;
export const ProductCategoryFragmentFragmentDoc = gql`
    fragment ProductCategoryFragment on ProductCategory {
  id
  name
  viewOrder
}
    `;
export const ProductFileFragmentFragmentDoc = gql`
    fragment ProductFileFragment on ProductFile {
  id
  fileName
  fileSize
  mimeType
  originalPath
  thumbnailPath
}
    `;
export const ProductPriceFragmentFragmentDoc = gql`
    fragment ProductPriceFragment on ProductPrice {
  id
  quantity
  price
  basicDueDate
}
    `;
export const ProductTagFragmentFragmentDoc = gql`
    fragment ProductTagFragment on ProductTag {
  id
  name
}
    `;
export const TypesettingParamMaterialFragmentFragmentDoc = gql`
    fragment TypesettingParamMaterialFragment on TypesettingParamMaterial {
  id
}
    `;
export const TypesettingParamRecyclingSymbolFragmentFragmentDoc = gql`
    fragment TypesettingParamRecyclingSymbolFragment on TypesettingParamRecyclingSymbol {
  id
}
    `;
export const TypesettingParamRecyclingSymbolTextFragmentFragmentDoc = gql`
    fragment TypesettingParamRecyclingSymbolTextFragment on TypesettingParamRecyclingSymbolText {
  id
}
    `;
export const TypesettingParamSizeFragmentFragmentDoc = gql`
    fragment TypesettingParamSizeFragment on TypesettingParamSize {
  id
}
    `;
export const TypesettingParamWarningFragmentFragmentDoc = gql`
    fragment TypesettingParamWarningFragment on TypesettingParamWarning {
  id
}
    `;
export const CreateAutoTypesettingDocument = gql`
    mutation CreateAutoTypesetting($input: CreateAutoTypesettingInput!) {
  createAutoTypesetting(input: $input) {
    orderProduct {
      ...OrderProductFragment
    }
  }
}
    ${OrderProductFragmentFragmentDoc}`;

/**
 * __useCreateAutoTypesettingMutation__
 *
 * To run a mutation, you first call `useCreateAutoTypesettingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutoTypesettingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAutoTypesettingMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutoTypesettingMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateAutoTypesettingMutation, schema.CreateAutoTypesettingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateAutoTypesettingMutation, schema.CreateAutoTypesettingMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateAutoTypesettingMutation, schema.CreateAutoTypesettingMutationVariables>(CreateAutoTypesettingDocument, options);
}
export type CreateAutoTypesettingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateAutoTypesettingMutation, schema.CreateAutoTypesettingMutationVariables>;
export const UpdateAutoTypesettingDocument = gql`
    mutation UpdateAutoTypesetting($input: UpdateAutoTypesettingInput!) {
  updateAutoTypesetting(input: $input) {
    orderProduct {
      ...OrderProductFragment
    }
    sonbpUri
    accessToken
    fileKey
  }
}
    ${OrderProductFragmentFragmentDoc}`;

/**
 * __useUpdateAutoTypesettingMutation__
 *
 * To run a mutation, you first call `useUpdateAutoTypesettingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoTypesettingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAutoTypesettingMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutoTypesettingMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateAutoTypesettingMutation, schema.UpdateAutoTypesettingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateAutoTypesettingMutation, schema.UpdateAutoTypesettingMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateAutoTypesettingMutation, schema.UpdateAutoTypesettingMutationVariables>(UpdateAutoTypesettingDocument, options);
}
export type UpdateAutoTypesettingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateAutoTypesettingMutation, schema.UpdateAutoTypesettingMutationVariables>;
export const CreateOneClientApplicationFlowDocument = gql`
    mutation CreateOneClientApplicationFlow($input: CreateOneClientApplicationFlowInput!) {
  createOneClientApplicationFlow(input: $input) {
    clientApplicationFlow {
      ...ClientApplicationFlowFragment
    }
  }
}
    ${ClientApplicationFlowFragmentFragmentDoc}`;

/**
 * __useCreateOneClientApplicationFlowMutation__
 *
 * To run a mutation, you first call `useCreateOneClientApplicationFlowMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientApplicationFlowMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneClientApplicationFlowMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientApplicationFlowMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneClientApplicationFlowMutation, schema.CreateOneClientApplicationFlowMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneClientApplicationFlowMutation, schema.CreateOneClientApplicationFlowMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneClientApplicationFlowMutation, schema.CreateOneClientApplicationFlowMutationVariables>(CreateOneClientApplicationFlowDocument, options);
}
export type CreateOneClientApplicationFlowMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneClientApplicationFlowMutation, schema.CreateOneClientApplicationFlowMutationVariables>;
export const RemoveOneClientApplicationFlowDocument = gql`
    mutation RemoveOneClientApplicationFlow($input: RemoveOneClientApplicationFlowInput!) {
  removeOneClientApplicationFlow(input: $input) {
    clientApplicationFlow {
      id
    }
  }
}
    `;

/**
 * __useRemoveOneClientApplicationFlowMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientApplicationFlowMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientApplicationFlowMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientApplicationFlowMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientApplicationFlowMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientApplicationFlowMutation, schema.RemoveOneClientApplicationFlowMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientApplicationFlowMutation, schema.RemoveOneClientApplicationFlowMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientApplicationFlowMutation, schema.RemoveOneClientApplicationFlowMutationVariables>(RemoveOneClientApplicationFlowDocument, options);
}
export type RemoveOneClientApplicationFlowMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientApplicationFlowMutation, schema.RemoveOneClientApplicationFlowMutationVariables>;
export const UpdateOneClientApplicationFlowDocument = gql`
    mutation UpdateOneClientApplicationFlow($input: UpdateOneClientApplicationFlowInput!) {
  updateOneClientApplicationFlow(input: $input) {
    clientApplicationFlow {
      ...ClientApplicationFlowFragment
    }
  }
}
    ${ClientApplicationFlowFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientApplicationFlowMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientApplicationFlowMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientApplicationFlowMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientApplicationFlowMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientApplicationFlowMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientApplicationFlowMutation, schema.UpdateOneClientApplicationFlowMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientApplicationFlowMutation, schema.UpdateOneClientApplicationFlowMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientApplicationFlowMutation, schema.UpdateOneClientApplicationFlowMutationVariables>(UpdateOneClientApplicationFlowDocument, options);
}
export type UpdateOneClientApplicationFlowMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientApplicationFlowMutation, schema.UpdateOneClientApplicationFlowMutationVariables>;
export const CreateOneClientApplicationFlowLevelDocument = gql`
    mutation CreateOneClientApplicationFlowLevel($input: CreateOneClientApplicationFlowLevelInput!) {
  createOneClientApplicationFlowLevel(input: $input) {
    clientApplicationFlowLevel {
      ...ClientApplicationFlowLevelFragment
    }
  }
}
    ${ClientApplicationFlowLevelFragmentFragmentDoc}`;

/**
 * __useCreateOneClientApplicationFlowLevelMutation__
 *
 * To run a mutation, you first call `useCreateOneClientApplicationFlowLevelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientApplicationFlowLevelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneClientApplicationFlowLevelMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientApplicationFlowLevelMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneClientApplicationFlowLevelMutation, schema.CreateOneClientApplicationFlowLevelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneClientApplicationFlowLevelMutation, schema.CreateOneClientApplicationFlowLevelMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneClientApplicationFlowLevelMutation, schema.CreateOneClientApplicationFlowLevelMutationVariables>(CreateOneClientApplicationFlowLevelDocument, options);
}
export type CreateOneClientApplicationFlowLevelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneClientApplicationFlowLevelMutation, schema.CreateOneClientApplicationFlowLevelMutationVariables>;
export const RemoveOneClientApplicationFlowLevelDocument = gql`
    mutation RemoveOneClientApplicationFlowLevel($input: RemoveOneClientApplicationFlowLevelInput!) {
  removeOneClientApplicationFlowLevel(input: $input) {
    clientApplicationFlowLevel {
      id
    }
  }
}
    `;

/**
 * __useRemoveOneClientApplicationFlowLevelMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientApplicationFlowLevelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientApplicationFlowLevelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientApplicationFlowLevelMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientApplicationFlowLevelMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientApplicationFlowLevelMutation, schema.RemoveOneClientApplicationFlowLevelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientApplicationFlowLevelMutation, schema.RemoveOneClientApplicationFlowLevelMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientApplicationFlowLevelMutation, schema.RemoveOneClientApplicationFlowLevelMutationVariables>(RemoveOneClientApplicationFlowLevelDocument, options);
}
export type RemoveOneClientApplicationFlowLevelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientApplicationFlowLevelMutation, schema.RemoveOneClientApplicationFlowLevelMutationVariables>;
export const UpdateOneClientApplicationFlowLevelDocument = gql`
    mutation UpdateOneClientApplicationFlowLevel($input: UpdateOneClientApplicationFlowLevelInput!) {
  updateOneClientApplicationFlowLevel(input: $input) {
    clientApplicationFlowLevel {
      ...ClientApplicationFlowLevelFragment
    }
  }
}
    ${ClientApplicationFlowLevelFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientApplicationFlowLevelMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientApplicationFlowLevelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientApplicationFlowLevelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientApplicationFlowLevelMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientApplicationFlowLevelMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientApplicationFlowLevelMutation, schema.UpdateOneClientApplicationFlowLevelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientApplicationFlowLevelMutation, schema.UpdateOneClientApplicationFlowLevelMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientApplicationFlowLevelMutation, schema.UpdateOneClientApplicationFlowLevelMutationVariables>(UpdateOneClientApplicationFlowLevelDocument, options);
}
export type UpdateOneClientApplicationFlowLevelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientApplicationFlowLevelMutation, schema.UpdateOneClientApplicationFlowLevelMutationVariables>;
export const CreateOneClientApplicationFlowRouteDocument = gql`
    mutation CreateOneClientApplicationFlowRoute($input: CreateOneClientApplicationFlowRouteInput!) {
  createOneClientApplicationFlowRoute(input: $input) {
    clientApplicationFlowRoute {
      ...ClientApplicationFlowRouteFragment
    }
  }
}
    ${ClientApplicationFlowRouteFragmentFragmentDoc}`;

/**
 * __useCreateOneClientApplicationFlowRouteMutation__
 *
 * To run a mutation, you first call `useCreateOneClientApplicationFlowRouteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientApplicationFlowRouteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneClientApplicationFlowRouteMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientApplicationFlowRouteMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneClientApplicationFlowRouteMutation, schema.CreateOneClientApplicationFlowRouteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneClientApplicationFlowRouteMutation, schema.CreateOneClientApplicationFlowRouteMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneClientApplicationFlowRouteMutation, schema.CreateOneClientApplicationFlowRouteMutationVariables>(CreateOneClientApplicationFlowRouteDocument, options);
}
export type CreateOneClientApplicationFlowRouteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneClientApplicationFlowRouteMutation, schema.CreateOneClientApplicationFlowRouteMutationVariables>;
export const RemoveOneClientApplicationFlowRouteDocument = gql`
    mutation RemoveOneClientApplicationFlowRoute($input: RemoveOneClientApplicationFlowRouteInput!) {
  removeOneClientApplicationFlowRoute(input: $input) {
    clientApplicationFlowRoute {
      id
    }
  }
}
    `;

/**
 * __useRemoveOneClientApplicationFlowRouteMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientApplicationFlowRouteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientApplicationFlowRouteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientApplicationFlowRouteMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientApplicationFlowRouteMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientApplicationFlowRouteMutation, schema.RemoveOneClientApplicationFlowRouteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientApplicationFlowRouteMutation, schema.RemoveOneClientApplicationFlowRouteMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientApplicationFlowRouteMutation, schema.RemoveOneClientApplicationFlowRouteMutationVariables>(RemoveOneClientApplicationFlowRouteDocument, options);
}
export type RemoveOneClientApplicationFlowRouteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientApplicationFlowRouteMutation, schema.RemoveOneClientApplicationFlowRouteMutationVariables>;
export const UpdateOneClientApplicationFlowRouteDocument = gql`
    mutation UpdateOneClientApplicationFlowRoute($input: UpdateOneClientApplicationFlowRouteInput!) {
  updateOneClientApplicationFlowRoute(input: $input) {
    clientApplicationFlowRoute {
      ...ClientApplicationFlowRouteFragment
    }
  }
}
    ${ClientApplicationFlowRouteFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientApplicationFlowRouteMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientApplicationFlowRouteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientApplicationFlowRouteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientApplicationFlowRouteMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientApplicationFlowRouteMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientApplicationFlowRouteMutation, schema.UpdateOneClientApplicationFlowRouteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientApplicationFlowRouteMutation, schema.UpdateOneClientApplicationFlowRouteMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientApplicationFlowRouteMutation, schema.UpdateOneClientApplicationFlowRouteMutationVariables>(UpdateOneClientApplicationFlowRouteDocument, options);
}
export type UpdateOneClientApplicationFlowRouteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientApplicationFlowRouteMutation, schema.UpdateOneClientApplicationFlowRouteMutationVariables>;
export const CreateOneClientContactDocument = gql`
    mutation CreateOneClientContact($input: CreateOneClientContactInput!) {
  createOneClientContact(input: $input) {
    clientContact {
      ...ClientContactFragment
    }
  }
}
    ${ClientContactFragmentFragmentDoc}`;

/**
 * __useCreateOneClientContactMutation__
 *
 * To run a mutation, you first call `useCreateOneClientContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneClientContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientContactMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneClientContactMutation, schema.CreateOneClientContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneClientContactMutation, schema.CreateOneClientContactMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneClientContactMutation, schema.CreateOneClientContactMutationVariables>(CreateOneClientContactDocument, options);
}
export type CreateOneClientContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneClientContactMutation, schema.CreateOneClientContactMutationVariables>;
export const RemoveOneClientContactDocument = gql`
    mutation RemoveOneClientContact($input: RemoveOneClientContactInput!) {
  removeOneClientContact(input: $input) {
    clientContact {
      ...ClientContactFragment
    }
  }
}
    ${ClientContactFragmentFragmentDoc}`;

/**
 * __useRemoveOneClientContactMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientContactMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientContactMutation, schema.RemoveOneClientContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientContactMutation, schema.RemoveOneClientContactMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientContactMutation, schema.RemoveOneClientContactMutationVariables>(RemoveOneClientContactDocument, options);
}
export type RemoveOneClientContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientContactMutation, schema.RemoveOneClientContactMutationVariables>;
export const UpdateOneClientContactDocument = gql`
    mutation UpdateOneClientContact($input: UpdateOneClientContactInput!) {
  updateOneClientContact(input: $input) {
    clientContact {
      ...ClientContactFragment
    }
  }
}
    ${ClientContactFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientContactMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientContactMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientContactMutation, schema.UpdateOneClientContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientContactMutation, schema.UpdateOneClientContactMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientContactMutation, schema.UpdateOneClientContactMutationVariables>(UpdateOneClientContactDocument, options);
}
export type UpdateOneClientContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientContactMutation, schema.UpdateOneClientContactMutationVariables>;
export const CreateOneClientDeliveryDocument = gql`
    mutation CreateOneClientDelivery($input: CreateOneClientDeliveryInput!) {
  createOneClientDelivery(input: $input) {
    clientDelivery {
      ...ClientDeliveryFragment
    }
  }
}
    ${ClientDeliveryFragmentFragmentDoc}`;

/**
 * __useCreateOneClientDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateOneClientDeliveryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientDeliveryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneClientDeliveryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientDeliveryMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneClientDeliveryMutation, schema.CreateOneClientDeliveryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneClientDeliveryMutation, schema.CreateOneClientDeliveryMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneClientDeliveryMutation, schema.CreateOneClientDeliveryMutationVariables>(CreateOneClientDeliveryDocument, options);
}
export type CreateOneClientDeliveryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneClientDeliveryMutation, schema.CreateOneClientDeliveryMutationVariables>;
export const RemoveOneClientDeliveryDocument = gql`
    mutation RemoveOneClientDelivery($input: RemoveOneClientDeliveryInput!) {
  removeOneClientDelivery(input: $input) {
    clientDelivery {
      ...ClientDeliveryFragment
    }
  }
}
    ${ClientDeliveryFragmentFragmentDoc}`;

/**
 * __useRemoveOneClientDeliveryMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientDeliveryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientDeliveryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientDeliveryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientDeliveryMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientDeliveryMutation, schema.RemoveOneClientDeliveryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientDeliveryMutation, schema.RemoveOneClientDeliveryMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientDeliveryMutation, schema.RemoveOneClientDeliveryMutationVariables>(RemoveOneClientDeliveryDocument, options);
}
export type RemoveOneClientDeliveryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientDeliveryMutation, schema.RemoveOneClientDeliveryMutationVariables>;
export const UpdateOneClientDeliveryDocument = gql`
    mutation UpdateOneClientDelivery($input: UpdateOneClientDeliveryInput!) {
  updateOneClientDelivery(input: $input) {
    clientDelivery {
      ...ClientDeliveryFragment
    }
  }
}
    ${ClientDeliveryFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientDeliveryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientDeliveryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientDeliveryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientDeliveryMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientDeliveryMutation, schema.UpdateOneClientDeliveryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientDeliveryMutation, schema.UpdateOneClientDeliveryMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientDeliveryMutation, schema.UpdateOneClientDeliveryMutationVariables>(UpdateOneClientDeliveryDocument, options);
}
export type UpdateOneClientDeliveryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientDeliveryMutation, schema.UpdateOneClientDeliveryMutationVariables>;
export const CreateOneClientShipperDocument = gql`
    mutation CreateOneClientShipper($input: CreateOneClientShipperInput!) {
  createOneClientShipper(input: $input) {
    clientShipper {
      ...ClientShipperFragment
    }
  }
}
    ${ClientShipperFragmentFragmentDoc}`;

/**
 * __useCreateOneClientShipperMutation__
 *
 * To run a mutation, you first call `useCreateOneClientShipperMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientShipperMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneClientShipperMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientShipperMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneClientShipperMutation, schema.CreateOneClientShipperMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneClientShipperMutation, schema.CreateOneClientShipperMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneClientShipperMutation, schema.CreateOneClientShipperMutationVariables>(CreateOneClientShipperDocument, options);
}
export type CreateOneClientShipperMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneClientShipperMutation, schema.CreateOneClientShipperMutationVariables>;
export const RemoveOneClientShipperDocument = gql`
    mutation RemoveOneClientShipper($input: RemoveOneClientShipperInput!) {
  removeOneClientShipper(input: $input) {
    clientShipper {
      ...ClientShipperFragment
    }
  }
}
    ${ClientShipperFragmentFragmentDoc}`;

/**
 * __useRemoveOneClientShipperMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientShipperMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientShipperMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientShipperMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientShipperMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientShipperMutation, schema.RemoveOneClientShipperMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientShipperMutation, schema.RemoveOneClientShipperMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientShipperMutation, schema.RemoveOneClientShipperMutationVariables>(RemoveOneClientShipperDocument, options);
}
export type RemoveOneClientShipperMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientShipperMutation, schema.RemoveOneClientShipperMutationVariables>;
export const UpdateOneClientShipperDocument = gql`
    mutation UpdateOneClientShipper($input: UpdateOneClientShipperInput!) {
  updateOneClientShipper(input: $input) {
    clientShipper {
      ...ClientShipperFragment
    }
  }
}
    ${ClientShipperFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientShipperMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientShipperMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientShipperMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientShipperMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientShipperMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientShipperMutation, schema.UpdateOneClientShipperMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientShipperMutation, schema.UpdateOneClientShipperMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientShipperMutation, schema.UpdateOneClientShipperMutationVariables>(UpdateOneClientShipperDocument, options);
}
export type UpdateOneClientShipperMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientShipperMutation, schema.UpdateOneClientShipperMutationVariables>;
export const RemoveOneClientStorageFileDocument = gql`
    mutation RemoveOneClientStorageFile($input: RemoveOneClientStorageFileInput!) {
  removeOneClientStorageFile(input: $input) {
    clientStorageFile {
      ...ClientStorageFileFragment
    }
  }
}
    ${ClientStorageFileFragmentFragmentDoc}`;

/**
 * __useRemoveOneClientStorageFileMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientStorageFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientStorageFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientStorageFileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientStorageFileMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientStorageFileMutation, schema.RemoveOneClientStorageFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientStorageFileMutation, schema.RemoveOneClientStorageFileMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientStorageFileMutation, schema.RemoveOneClientStorageFileMutationVariables>(RemoveOneClientStorageFileDocument, options);
}
export type RemoveOneClientStorageFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientStorageFileMutation, schema.RemoveOneClientStorageFileMutationVariables>;
export const UpdateOneClientStorageFileDocument = gql`
    mutation UpdateOneClientStorageFile($input: UpdateOneClientStorageFileInput!) {
  updateOneClientStorageFile(input: $input) {
    clientStorageFile {
      ...ClientStorageFileFragment
    }
  }
}
    ${ClientStorageFileFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientStorageFileMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientStorageFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientStorageFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientStorageFileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientStorageFileMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientStorageFileMutation, schema.UpdateOneClientStorageFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientStorageFileMutation, schema.UpdateOneClientStorageFileMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientStorageFileMutation, schema.UpdateOneClientStorageFileMutationVariables>(UpdateOneClientStorageFileDocument, options);
}
export type UpdateOneClientStorageFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientStorageFileMutation, schema.UpdateOneClientStorageFileMutationVariables>;
export const CreateOneClientUserDocument = gql`
    mutation CreateOneClientUser($input: CreateOneClientUserInput!) {
  createOneClientUser(input: $input) {
    clientUser {
      ...ClientUserFragment
    }
  }
}
    ${ClientUserFragmentFragmentDoc}`;

/**
 * __useCreateOneClientUserMutation__
 *
 * To run a mutation, you first call `useCreateOneClientUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneClientUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientUserMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneClientUserMutation, schema.CreateOneClientUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneClientUserMutation, schema.CreateOneClientUserMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneClientUserMutation, schema.CreateOneClientUserMutationVariables>(CreateOneClientUserDocument, options);
}
export type CreateOneClientUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneClientUserMutation, schema.CreateOneClientUserMutationVariables>;
export const RemoveOneClientUserDocument = gql`
    mutation RemoveOneClientUser($input: RemoveOneClientUserInput!) {
  removeOneClientUser(input: $input) {
    clientUser {
      ...ClientUserFragment
    }
  }
}
    ${ClientUserFragmentFragmentDoc}`;

/**
 * __useRemoveOneClientUserMutation__
 *
 * To run a mutation, you first call `useRemoveOneClientUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneClientUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneClientUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneClientUserMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneClientUserMutation, schema.RemoveOneClientUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneClientUserMutation, schema.RemoveOneClientUserMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneClientUserMutation, schema.RemoveOneClientUserMutationVariables>(RemoveOneClientUserDocument, options);
}
export type RemoveOneClientUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneClientUserMutation, schema.RemoveOneClientUserMutationVariables>;
export const UpdateOneClientUserDocument = gql`
    mutation UpdateOneClientUser($input: UpdateOneClientUserInput!) {
  updateOneClientUser(input: $input) {
    clientUser {
      ...ClientUserFragment
    }
  }
}
    ${ClientUserFragmentFragmentDoc}`;

/**
 * __useUpdateOneClientUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneClientUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientUserMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneClientUserMutation, schema.UpdateOneClientUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneClientUserMutation, schema.UpdateOneClientUserMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneClientUserMutation, schema.UpdateOneClientUserMutationVariables>(UpdateOneClientUserDocument, options);
}
export type UpdateOneClientUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneClientUserMutation, schema.UpdateOneClientUserMutationVariables>;
export const UpdateOneInnerCartonParamDocument = gql`
    mutation UpdateOneInnerCartonParam($input: UpdateOneInnerCartonParamInput!) {
  updateOneInnerCartonParam(input: $input) {
    innerCartonParam {
      ...InnerCartonParamFragment
    }
  }
}
    ${InnerCartonParamFragmentFragmentDoc}`;

/**
 * __useUpdateOneInnerCartonParamMutation__
 *
 * To run a mutation, you first call `useUpdateOneInnerCartonParamMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneInnerCartonParamMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneInnerCartonParamMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneInnerCartonParamMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneInnerCartonParamMutation, schema.UpdateOneInnerCartonParamMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneInnerCartonParamMutation, schema.UpdateOneInnerCartonParamMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneInnerCartonParamMutation, schema.UpdateOneInnerCartonParamMutationVariables>(UpdateOneInnerCartonParamDocument, options);
}
export type UpdateOneInnerCartonParamMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneInnerCartonParamMutation, schema.UpdateOneInnerCartonParamMutationVariables>;
export const CancelOrderDocument = gql`
    mutation CancelOrder($input: CancelOrderInput!) {
  cancelOrder(input: $input) {
    order {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCancelOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.CancelOrderMutation, schema.CancelOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CancelOrderMutation, schema.CancelOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CancelOrderMutation, schema.CancelOrderMutationVariables>(CancelOrderDocument, options);
}
export type CancelOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CancelOrderMutation, schema.CancelOrderMutationVariables>;
export const CopyOneOrderDocument = gql`
    mutation CopyOneOrder($input: CopyOneOrderInput!) {
  copyOneOrder(input: $input) {
    order {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useCopyOneOrderMutation__
 *
 * To run a mutation, you first call `useCopyOneOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCopyOneOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCopyOneOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCopyOneOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.CopyOneOrderMutation, schema.CopyOneOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CopyOneOrderMutation, schema.CopyOneOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CopyOneOrderMutation, schema.CopyOneOrderMutationVariables>(CopyOneOrderDocument, options);
}
export type CopyOneOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CopyOneOrderMutation, schema.CopyOneOrderMutationVariables>;
export const CreateOneOrderDocument = gql`
    mutation CreateOneOrder($input: CreateOneOrderInput!) {
  createOneOrder(input: $input) {
    order {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useCreateOneOrderMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneOrderMutation, schema.CreateOneOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneOrderMutation, schema.CreateOneOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneOrderMutation, schema.CreateOneOrderMutationVariables>(CreateOneOrderDocument, options);
}
export type CreateOneOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneOrderMutation, schema.CreateOneOrderMutationVariables>;
export const RemoveOneOrderDocument = gql`
    mutation RemoveOneOrder($input: RemoveOneOrderInput!) {
  removeOneOrder(input: $input) {
    order {
      id
    }
  }
}
    `;

/**
 * __useRemoveOneOrderMutation__
 *
 * To run a mutation, you first call `useRemoveOneOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderMutation, schema.RemoveOneOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderMutation, schema.RemoveOneOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneOrderMutation, schema.RemoveOneOrderMutationVariables>(RemoveOneOrderDocument, options);
}
export type RemoveOneOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneOrderMutation, schema.RemoveOneOrderMutationVariables>;
export const ReorderOrderDocument = gql`
    mutation ReorderOrder($input: ReorderOrderInput!) {
  reorderOrder(input: $input) {
    order {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useReorderOrderMutation__
 *
 * To run a mutation, you first call `useReorderOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReorderOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReorderOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useReorderOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.ReorderOrderMutation, schema.ReorderOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.ReorderOrderMutation, schema.ReorderOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.ReorderOrderMutation, schema.ReorderOrderMutationVariables>(ReorderOrderDocument, options);
}
export type ReorderOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.ReorderOrderMutation, schema.ReorderOrderMutationVariables>;
export const UpdateOneOrderDocument = gql`
    mutation UpdateOneOrder($input: UpdateOneOrderInput!) {
  updateOneOrder(input: $input) {
    order {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useUpdateOneOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables>(UpdateOneOrderDocument, options);
}
export type UpdateOneOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables>;
export const ApproveManyOrderApprovalRecordDocument = gql`
    mutation ApproveManyOrderApprovalRecord($input: ApproveManyOrderApprovalRecordInput!) {
  approveManyOrderApprovalRecord(input: $input) {
    orderApprovalRecords {
      ...OrderApprovalRecordFragment
    }
  }
}
    ${OrderApprovalRecordFragmentFragmentDoc}`;

/**
 * __useApproveManyOrderApprovalRecordMutation__
 *
 * To run a mutation, you first call `useApproveManyOrderApprovalRecordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useApproveManyOrderApprovalRecordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useApproveManyOrderApprovalRecordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useApproveManyOrderApprovalRecordMutation(options: VueApolloComposable.UseMutationOptions<schema.ApproveManyOrderApprovalRecordMutation, schema.ApproveManyOrderApprovalRecordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.ApproveManyOrderApprovalRecordMutation, schema.ApproveManyOrderApprovalRecordMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.ApproveManyOrderApprovalRecordMutation, schema.ApproveManyOrderApprovalRecordMutationVariables>(ApproveManyOrderApprovalRecordDocument, options);
}
export type ApproveManyOrderApprovalRecordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.ApproveManyOrderApprovalRecordMutation, schema.ApproveManyOrderApprovalRecordMutationVariables>;
export const RejectOrderApprovalRecordDocument = gql`
    mutation RejectOrderApprovalRecord($input: RejectOrderApprovalRecordInput!) {
  rejectOrderApprovalRecord(input: $input) {
    orderApprovalRecord {
      ...OrderApprovalRecordFragment
    }
    orderComment {
      ...OrderCommentFragment
    }
  }
}
    ${OrderApprovalRecordFragmentFragmentDoc}
${OrderCommentFragmentFragmentDoc}`;

/**
 * __useRejectOrderApprovalRecordMutation__
 *
 * To run a mutation, you first call `useRejectOrderApprovalRecordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRejectOrderApprovalRecordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRejectOrderApprovalRecordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRejectOrderApprovalRecordMutation(options: VueApolloComposable.UseMutationOptions<schema.RejectOrderApprovalRecordMutation, schema.RejectOrderApprovalRecordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RejectOrderApprovalRecordMutation, schema.RejectOrderApprovalRecordMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RejectOrderApprovalRecordMutation, schema.RejectOrderApprovalRecordMutationVariables>(RejectOrderApprovalRecordDocument, options);
}
export type RejectOrderApprovalRecordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RejectOrderApprovalRecordMutation, schema.RejectOrderApprovalRecordMutationVariables>;
export const CreateOneOrderCommentDocument = gql`
    mutation CreateOneOrderComment($input: CreateOneOrderCommentInput!) {
  createOneOrderComment(input: $input) {
    orderComment {
      ...OrderCommentFragment
    }
  }
}
    ${OrderCommentFragmentFragmentDoc}`;

/**
 * __useCreateOneOrderCommentMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderCommentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderCommentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneOrderCommentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneOrderCommentMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables>(CreateOneOrderCommentDocument, options);
}
export type CreateOneOrderCommentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables>;
export const CreateOneOrderContactDocument = gql`
    mutation CreateOneOrderContact($input: CreateOneOrderContactInput!) {
  createOneOrderContact(input: $input) {
    orderContact {
      ...OrderContactFragment
    }
  }
}
    ${OrderContactFragmentFragmentDoc}`;

/**
 * __useCreateOneOrderContactMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneOrderContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneOrderContactMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneOrderContactMutation, schema.CreateOneOrderContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneOrderContactMutation, schema.CreateOneOrderContactMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneOrderContactMutation, schema.CreateOneOrderContactMutationVariables>(CreateOneOrderContactDocument, options);
}
export type CreateOneOrderContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneOrderContactMutation, schema.CreateOneOrderContactMutationVariables>;
export const RemoveOneOrderContactDocument = gql`
    mutation RemoveOneOrderContact($input: RemoveOneOrderContactInput!) {
  removeOneOrderContact(input: $input) {
    orderContact {
      ...OrderContactFragment
    }
  }
}
    ${OrderContactFragmentFragmentDoc}`;

/**
 * __useRemoveOneOrderContactMutation__
 *
 * To run a mutation, you first call `useRemoveOneOrderContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneOrderContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneOrderContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneOrderContactMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderContactMutation, schema.RemoveOneOrderContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderContactMutation, schema.RemoveOneOrderContactMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneOrderContactMutation, schema.RemoveOneOrderContactMutationVariables>(RemoveOneOrderContactDocument, options);
}
export type RemoveOneOrderContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneOrderContactMutation, schema.RemoveOneOrderContactMutationVariables>;
export const UpdateOneOrderContactDocument = gql`
    mutation UpdateOneOrderContact($input: UpdateOneOrderContactInput!) {
  updateOneOrderContact(input: $input) {
    orderContact {
      ...OrderContactFragment
    }
  }
}
    ${OrderContactFragmentFragmentDoc}`;

/**
 * __useUpdateOneOrderContactMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneOrderContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrderContactMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderContactMutation, schema.UpdateOneOrderContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderContactMutation, schema.UpdateOneOrderContactMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneOrderContactMutation, schema.UpdateOneOrderContactMutationVariables>(UpdateOneOrderContactDocument, options);
}
export type UpdateOneOrderContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneOrderContactMutation, schema.UpdateOneOrderContactMutationVariables>;
export const CreateOneOrderDeliveryDocument = gql`
    mutation CreateOneOrderDelivery($input: CreateOneOrderDeliveryInput!) {
  createOneOrderDelivery(input: $input) {
    orderDelivery {
      ...OrderDeliveryFragment
    }
  }
}
    ${OrderDeliveryFragmentFragmentDoc}`;

/**
 * __useCreateOneOrderDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderDeliveryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderDeliveryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneOrderDeliveryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneOrderDeliveryMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneOrderDeliveryMutation, schema.CreateOneOrderDeliveryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneOrderDeliveryMutation, schema.CreateOneOrderDeliveryMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneOrderDeliveryMutation, schema.CreateOneOrderDeliveryMutationVariables>(CreateOneOrderDeliveryDocument, options);
}
export type CreateOneOrderDeliveryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneOrderDeliveryMutation, schema.CreateOneOrderDeliveryMutationVariables>;
export const RemoveOneOrderDeliveryDocument = gql`
    mutation RemoveOneOrderDelivery($input: RemoveOneOrderDeliveryInput!) {
  removeOneOrderDelivery(input: $input) {
    orderDelivery {
      ...OrderDeliveryFragment
    }
  }
}
    ${OrderDeliveryFragmentFragmentDoc}`;

/**
 * __useRemoveOneOrderDeliveryMutation__
 *
 * To run a mutation, you first call `useRemoveOneOrderDeliveryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneOrderDeliveryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneOrderDeliveryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneOrderDeliveryMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderDeliveryMutation, schema.RemoveOneOrderDeliveryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderDeliveryMutation, schema.RemoveOneOrderDeliveryMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneOrderDeliveryMutation, schema.RemoveOneOrderDeliveryMutationVariables>(RemoveOneOrderDeliveryDocument, options);
}
export type RemoveOneOrderDeliveryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneOrderDeliveryMutation, schema.RemoveOneOrderDeliveryMutationVariables>;
export const UpdateOneOrderDeliveryDocument = gql`
    mutation UpdateOneOrderDelivery($input: UpdateOneOrderDeliveryInput!) {
  updateOneOrderDelivery(input: $input) {
    orderDelivery {
      ...OrderDeliveryFragment
    }
  }
}
    ${OrderDeliveryFragmentFragmentDoc}`;

/**
 * __useUpdateOneOrderDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderDeliveryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderDeliveryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneOrderDeliveryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrderDeliveryMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderDeliveryMutation, schema.UpdateOneOrderDeliveryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderDeliveryMutation, schema.UpdateOneOrderDeliveryMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneOrderDeliveryMutation, schema.UpdateOneOrderDeliveryMutationVariables>(UpdateOneOrderDeliveryDocument, options);
}
export type UpdateOneOrderDeliveryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneOrderDeliveryMutation, schema.UpdateOneOrderDeliveryMutationVariables>;
export const UpdateOneOrderDeliveryShipperDocument = gql`
    mutation UpdateOneOrderDeliveryShipper($input: UpdateOneOrderDeliveryShipperInput!) {
  updateOneOrderDeliveryShipper(input: $input) {
    orderDeliveryShipper {
      ...OrderDeliveryShipperFragment
    }
  }
}
    ${OrderDeliveryShipperFragmentFragmentDoc}`;

/**
 * __useUpdateOneOrderDeliveryShipperMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderDeliveryShipperMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderDeliveryShipperMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneOrderDeliveryShipperMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrderDeliveryShipperMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderDeliveryShipperMutation, schema.UpdateOneOrderDeliveryShipperMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderDeliveryShipperMutation, schema.UpdateOneOrderDeliveryShipperMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneOrderDeliveryShipperMutation, schema.UpdateOneOrderDeliveryShipperMutationVariables>(UpdateOneOrderDeliveryShipperDocument, options);
}
export type UpdateOneOrderDeliveryShipperMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneOrderDeliveryShipperMutation, schema.UpdateOneOrderDeliveryShipperMutationVariables>;
export const RemoveOneOrderProductFileDocument = gql`
    mutation RemoveOneOrderProductFile($input: RemoveOneOrderProductFileInput!) {
  removeOneOrderProductFile(input: $input) {
    orderProductFile {
      ...OrderProductFileFragment
    }
  }
}
    ${OrderProductFileFragmentFragmentDoc}`;

/**
 * __useRemoveOneOrderProductFileMutation__
 *
 * To run a mutation, you first call `useRemoveOneOrderProductFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneOrderProductFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneOrderProductFileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneOrderProductFileMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderProductFileMutation, schema.RemoveOneOrderProductFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderProductFileMutation, schema.RemoveOneOrderProductFileMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneOrderProductFileMutation, schema.RemoveOneOrderProductFileMutationVariables>(RemoveOneOrderProductFileDocument, options);
}
export type RemoveOneOrderProductFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneOrderProductFileMutation, schema.RemoveOneOrderProductFileMutationVariables>;
export const UpdateOneOuterCartonParamDocument = gql`
    mutation UpdateOneOuterCartonParam($input: UpdateOneOuterCartonParamInput!) {
  updateOneOuterCartonParam(input: $input) {
    outerCartonParam {
      ...OuterCartonParamFragment
    }
  }
}
    ${OuterCartonParamFragmentFragmentDoc}`;

/**
 * __useUpdateOneOuterCartonParamMutation__
 *
 * To run a mutation, you first call `useUpdateOneOuterCartonParamMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOuterCartonParamMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneOuterCartonParamMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOuterCartonParamMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneOuterCartonParamMutation, schema.UpdateOneOuterCartonParamMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneOuterCartonParamMutation, schema.UpdateOneOuterCartonParamMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneOuterCartonParamMutation, schema.UpdateOneOuterCartonParamMutationVariables>(UpdateOneOuterCartonParamDocument, options);
}
export type UpdateOneOuterCartonParamMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneOuterCartonParamMutation, schema.UpdateOneOuterCartonParamMutationVariables>;
export const UpdateOnePriceLabelParamDocument = gql`
    mutation UpdateOnePriceLabelParam($input: UpdateOnePriceLabelParamInput!) {
  updateOnePriceLabelParam(input: $input) {
    priceLabelParam {
      ...PriceLabelParamFragment
    }
  }
}
    ${PriceLabelParamFragmentFragmentDoc}`;

/**
 * __useUpdateOnePriceLabelParamMutation__
 *
 * To run a mutation, you first call `useUpdateOnePriceLabelParamMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnePriceLabelParamMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOnePriceLabelParamMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnePriceLabelParamMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOnePriceLabelParamMutation, schema.UpdateOnePriceLabelParamMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOnePriceLabelParamMutation, schema.UpdateOnePriceLabelParamMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOnePriceLabelParamMutation, schema.UpdateOnePriceLabelParamMutationVariables>(UpdateOnePriceLabelParamDocument, options);
}
export type UpdateOnePriceLabelParamMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOnePriceLabelParamMutation, schema.UpdateOnePriceLabelParamMutationVariables>;
export const UpdateOneTypesettingParamDocument = gql`
    mutation UpdateOneTypesettingParam($input: UpdateOneTypesettingParamInput!) {
  updateOneTypesettingParam(input: $input) {
    typesettingParam {
      ...TypesettingParamFragment
    }
  }
}
    ${TypesettingParamFragmentFragmentDoc}`;

/**
 * __useUpdateOneTypesettingParamMutation__
 *
 * To run a mutation, you first call `useUpdateOneTypesettingParamMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTypesettingParamMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneTypesettingParamMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneTypesettingParamMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamMutation, schema.UpdateOneTypesettingParamMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamMutation, schema.UpdateOneTypesettingParamMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneTypesettingParamMutation, schema.UpdateOneTypesettingParamMutationVariables>(UpdateOneTypesettingParamDocument, options);
}
export type UpdateOneTypesettingParamMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneTypesettingParamMutation, schema.UpdateOneTypesettingParamMutationVariables>;
export const CreateOneTypesettingParamMaterialDocument = gql`
    mutation CreateOneTypesettingParamMaterial($input: CreateOneTypesettingParamMaterialInput!) {
  createOneTypesettingParamMaterial(input: $input) {
    typesettingParamMaterial {
      ...TypesettingParamMaterialFragment
    }
  }
}
    ${TypesettingParamMaterialFragmentFragmentDoc}`;

/**
 * __useCreateOneTypesettingParamMaterialMutation__
 *
 * To run a mutation, you first call `useCreateOneTypesettingParamMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTypesettingParamMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneTypesettingParamMaterialMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTypesettingParamMaterialMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamMaterialMutation, schema.CreateOneTypesettingParamMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamMaterialMutation, schema.CreateOneTypesettingParamMaterialMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneTypesettingParamMaterialMutation, schema.CreateOneTypesettingParamMaterialMutationVariables>(CreateOneTypesettingParamMaterialDocument, options);
}
export type CreateOneTypesettingParamMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneTypesettingParamMaterialMutation, schema.CreateOneTypesettingParamMaterialMutationVariables>;
export const RemoveOneTypesettingParamMaterialDocument = gql`
    mutation RemoveOneTypesettingParamMaterial($input: RemoveOneTypesettingParamMaterialInput!) {
  removeOneTypesettingParamMaterial(input: $input) {
    typesettingParamMaterial {
      ...TypesettingParamMaterialFragment
    }
  }
}
    ${TypesettingParamMaterialFragmentFragmentDoc}`;

/**
 * __useRemoveOneTypesettingParamMaterialMutation__
 *
 * To run a mutation, you first call `useRemoveOneTypesettingParamMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTypesettingParamMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneTypesettingParamMaterialMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneTypesettingParamMaterialMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamMaterialMutation, schema.RemoveOneTypesettingParamMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamMaterialMutation, schema.RemoveOneTypesettingParamMaterialMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneTypesettingParamMaterialMutation, schema.RemoveOneTypesettingParamMaterialMutationVariables>(RemoveOneTypesettingParamMaterialDocument, options);
}
export type RemoveOneTypesettingParamMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneTypesettingParamMaterialMutation, schema.RemoveOneTypesettingParamMaterialMutationVariables>;
export const UpdateOneTypesettingParamMaterialDocument = gql`
    mutation UpdateOneTypesettingParamMaterial($input: UpdateOneTypesettingParamMaterialInput!) {
  updateOneTypesettingParamMaterial(input: $input) {
    typesettingParamMaterial {
      ...TypesettingParamMaterialFragment
    }
  }
}
    ${TypesettingParamMaterialFragmentFragmentDoc}`;

/**
 * __useUpdateOneTypesettingParamMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateOneTypesettingParamMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTypesettingParamMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneTypesettingParamMaterialMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneTypesettingParamMaterialMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamMaterialMutation, schema.UpdateOneTypesettingParamMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamMaterialMutation, schema.UpdateOneTypesettingParamMaterialMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneTypesettingParamMaterialMutation, schema.UpdateOneTypesettingParamMaterialMutationVariables>(UpdateOneTypesettingParamMaterialDocument, options);
}
export type UpdateOneTypesettingParamMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneTypesettingParamMaterialMutation, schema.UpdateOneTypesettingParamMaterialMutationVariables>;
export const CreateOneTypesettingParamRecyclingSymbolDocument = gql`
    mutation CreateOneTypesettingParamRecyclingSymbol($input: CreateOneTypesettingParamRecyclingSymbolInput!) {
  createOneTypesettingParamRecyclingSymbol(input: $input) {
    typesettingParamRecyclingSymbol {
      ...TypesettingParamRecyclingSymbolFragment
    }
  }
}
    ${TypesettingParamRecyclingSymbolFragmentFragmentDoc}`;

/**
 * __useCreateOneTypesettingParamRecyclingSymbolMutation__
 *
 * To run a mutation, you first call `useCreateOneTypesettingParamRecyclingSymbolMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTypesettingParamRecyclingSymbolMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneTypesettingParamRecyclingSymbolMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTypesettingParamRecyclingSymbolMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamRecyclingSymbolMutation, schema.CreateOneTypesettingParamRecyclingSymbolMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamRecyclingSymbolMutation, schema.CreateOneTypesettingParamRecyclingSymbolMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneTypesettingParamRecyclingSymbolMutation, schema.CreateOneTypesettingParamRecyclingSymbolMutationVariables>(CreateOneTypesettingParamRecyclingSymbolDocument, options);
}
export type CreateOneTypesettingParamRecyclingSymbolMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneTypesettingParamRecyclingSymbolMutation, schema.CreateOneTypesettingParamRecyclingSymbolMutationVariables>;
export const RemoveOneTypesettingParamRecyclingSymbolDocument = gql`
    mutation RemoveOneTypesettingParamRecyclingSymbol($input: RemoveOneTypesettingParamRecyclingSymbolInput!) {
  removeOneTypesettingParamRecyclingSymbol(input: $input) {
    typesettingParamRecyclingSymbol {
      ...TypesettingParamRecyclingSymbolFragment
    }
  }
}
    ${TypesettingParamRecyclingSymbolFragmentFragmentDoc}`;

/**
 * __useRemoveOneTypesettingParamRecyclingSymbolMutation__
 *
 * To run a mutation, you first call `useRemoveOneTypesettingParamRecyclingSymbolMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTypesettingParamRecyclingSymbolMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneTypesettingParamRecyclingSymbolMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneTypesettingParamRecyclingSymbolMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamRecyclingSymbolMutation, schema.RemoveOneTypesettingParamRecyclingSymbolMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamRecyclingSymbolMutation, schema.RemoveOneTypesettingParamRecyclingSymbolMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneTypesettingParamRecyclingSymbolMutation, schema.RemoveOneTypesettingParamRecyclingSymbolMutationVariables>(RemoveOneTypesettingParamRecyclingSymbolDocument, options);
}
export type RemoveOneTypesettingParamRecyclingSymbolMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneTypesettingParamRecyclingSymbolMutation, schema.RemoveOneTypesettingParamRecyclingSymbolMutationVariables>;
export const UpdateOneTypesettingParamSizeDocument = gql`
    mutation UpdateOneTypesettingParamSize($input: UpdateOneTypesettingParamSizeInput!) {
  updateOneTypesettingParamSize(input: $input) {
    typesettingParamSize {
      ...TypesettingParamSizeFragment
    }
  }
}
    ${TypesettingParamSizeFragmentFragmentDoc}`;

/**
 * __useUpdateOneTypesettingParamSizeMutation__
 *
 * To run a mutation, you first call `useUpdateOneTypesettingParamSizeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTypesettingParamSizeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneTypesettingParamSizeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneTypesettingParamSizeMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamSizeMutation, schema.UpdateOneTypesettingParamSizeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamSizeMutation, schema.UpdateOneTypesettingParamSizeMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneTypesettingParamSizeMutation, schema.UpdateOneTypesettingParamSizeMutationVariables>(UpdateOneTypesettingParamSizeDocument, options);
}
export type UpdateOneTypesettingParamSizeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneTypesettingParamSizeMutation, schema.UpdateOneTypesettingParamSizeMutationVariables>;
export const CreateOneTypesettingParamRecyclingSymbolTextDocument = gql`
    mutation CreateOneTypesettingParamRecyclingSymbolText($input: CreateOneTypesettingParamRecyclingSymbolTextInput!) {
  createOneTypesettingParamRecyclingSymbolText(input: $input) {
    typesettingParamRecyclingSymbolText {
      ...TypesettingParamRecyclingSymbolTextFragment
    }
  }
}
    ${TypesettingParamRecyclingSymbolTextFragmentFragmentDoc}`;

/**
 * __useCreateOneTypesettingParamRecyclingSymbolTextMutation__
 *
 * To run a mutation, you first call `useCreateOneTypesettingParamRecyclingSymbolTextMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTypesettingParamRecyclingSymbolTextMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneTypesettingParamRecyclingSymbolTextMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTypesettingParamRecyclingSymbolTextMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamRecyclingSymbolTextMutation, schema.CreateOneTypesettingParamRecyclingSymbolTextMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamRecyclingSymbolTextMutation, schema.CreateOneTypesettingParamRecyclingSymbolTextMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneTypesettingParamRecyclingSymbolTextMutation, schema.CreateOneTypesettingParamRecyclingSymbolTextMutationVariables>(CreateOneTypesettingParamRecyclingSymbolTextDocument, options);
}
export type CreateOneTypesettingParamRecyclingSymbolTextMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneTypesettingParamRecyclingSymbolTextMutation, schema.CreateOneTypesettingParamRecyclingSymbolTextMutationVariables>;
export const RemoveOneTypesettingParamRecyclingSymbolTextDocument = gql`
    mutation RemoveOneTypesettingParamRecyclingSymbolText($input: RemoveOneTypesettingParamRecyclingSymbolTextInput!) {
  removeOneTypesettingParamRecyclingSymbolText(input: $input) {
    typesettingParamRecyclingSymbolText {
      ...TypesettingParamRecyclingSymbolTextFragment
    }
  }
}
    ${TypesettingParamRecyclingSymbolTextFragmentFragmentDoc}`;

/**
 * __useRemoveOneTypesettingParamRecyclingSymbolTextMutation__
 *
 * To run a mutation, you first call `useRemoveOneTypesettingParamRecyclingSymbolTextMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTypesettingParamRecyclingSymbolTextMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneTypesettingParamRecyclingSymbolTextMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneTypesettingParamRecyclingSymbolTextMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamRecyclingSymbolTextMutation, schema.RemoveOneTypesettingParamRecyclingSymbolTextMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamRecyclingSymbolTextMutation, schema.RemoveOneTypesettingParamRecyclingSymbolTextMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneTypesettingParamRecyclingSymbolTextMutation, schema.RemoveOneTypesettingParamRecyclingSymbolTextMutationVariables>(RemoveOneTypesettingParamRecyclingSymbolTextDocument, options);
}
export type RemoveOneTypesettingParamRecyclingSymbolTextMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneTypesettingParamRecyclingSymbolTextMutation, schema.RemoveOneTypesettingParamRecyclingSymbolTextMutationVariables>;
export const CreateOneTypesettingParamSizeDocument = gql`
    mutation CreateOneTypesettingParamSize($input: CreateOneTypesettingParamSizeInput!) {
  createOneTypesettingParamSize(input: $input) {
    typesettingParamSize {
      ...TypesettingParamSizeFragment
    }
  }
}
    ${TypesettingParamSizeFragmentFragmentDoc}`;

/**
 * __useCreateOneTypesettingParamSizeMutation__
 *
 * To run a mutation, you first call `useCreateOneTypesettingParamSizeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTypesettingParamSizeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneTypesettingParamSizeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTypesettingParamSizeMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamSizeMutation, schema.CreateOneTypesettingParamSizeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamSizeMutation, schema.CreateOneTypesettingParamSizeMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneTypesettingParamSizeMutation, schema.CreateOneTypesettingParamSizeMutationVariables>(CreateOneTypesettingParamSizeDocument, options);
}
export type CreateOneTypesettingParamSizeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneTypesettingParamSizeMutation, schema.CreateOneTypesettingParamSizeMutationVariables>;
export const RemoveOneTypesettingParamSizeDocument = gql`
    mutation RemoveOneTypesettingParamSize($input: RemoveOneTypesettingParamSizeInput!) {
  removeOneTypesettingParamSize(input: $input) {
    typesettingParamSize {
      ...TypesettingParamSizeFragment
    }
  }
}
    ${TypesettingParamSizeFragmentFragmentDoc}`;

/**
 * __useRemoveOneTypesettingParamSizeMutation__
 *
 * To run a mutation, you first call `useRemoveOneTypesettingParamSizeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTypesettingParamSizeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneTypesettingParamSizeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneTypesettingParamSizeMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamSizeMutation, schema.RemoveOneTypesettingParamSizeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamSizeMutation, schema.RemoveOneTypesettingParamSizeMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneTypesettingParamSizeMutation, schema.RemoveOneTypesettingParamSizeMutationVariables>(RemoveOneTypesettingParamSizeDocument, options);
}
export type RemoveOneTypesettingParamSizeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneTypesettingParamSizeMutation, schema.RemoveOneTypesettingParamSizeMutationVariables>;
export const CreateOneTypesettingParamWarningDocument = gql`
    mutation CreateOneTypesettingParamWarning($input: CreateOneTypesettingParamWarningInput!) {
  createOneTypesettingParamWarning(input: $input) {
    typesettingParamWarning {
      ...TypesettingParamWarningFragment
    }
  }
}
    ${TypesettingParamWarningFragmentFragmentDoc}`;

/**
 * __useCreateOneTypesettingParamWarningMutation__
 *
 * To run a mutation, you first call `useCreateOneTypesettingParamWarningMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTypesettingParamWarningMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneTypesettingParamWarningMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTypesettingParamWarningMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamWarningMutation, schema.CreateOneTypesettingParamWarningMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneTypesettingParamWarningMutation, schema.CreateOneTypesettingParamWarningMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneTypesettingParamWarningMutation, schema.CreateOneTypesettingParamWarningMutationVariables>(CreateOneTypesettingParamWarningDocument, options);
}
export type CreateOneTypesettingParamWarningMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneTypesettingParamWarningMutation, schema.CreateOneTypesettingParamWarningMutationVariables>;
export const RemoveOneTypesettingParamWarningDocument = gql`
    mutation RemoveOneTypesettingParamWarning($input: RemoveOneTypesettingParamWarningInput!) {
  removeOneTypesettingParamWarning(input: $input) {
    typesettingParamWarning {
      ...TypesettingParamWarningFragment
    }
  }
}
    ${TypesettingParamWarningFragmentFragmentDoc}`;

/**
 * __useRemoveOneTypesettingParamWarningMutation__
 *
 * To run a mutation, you first call `useRemoveOneTypesettingParamWarningMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTypesettingParamWarningMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneTypesettingParamWarningMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneTypesettingParamWarningMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamWarningMutation, schema.RemoveOneTypesettingParamWarningMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneTypesettingParamWarningMutation, schema.RemoveOneTypesettingParamWarningMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneTypesettingParamWarningMutation, schema.RemoveOneTypesettingParamWarningMutationVariables>(RemoveOneTypesettingParamWarningDocument, options);
}
export type RemoveOneTypesettingParamWarningMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneTypesettingParamWarningMutation, schema.RemoveOneTypesettingParamWarningMutationVariables>;
export const UpdateOneTypesettingParamWarningDocument = gql`
    mutation UpdateOneTypesettingParamWarning($input: UpdateOneTypesettingParamWarningInput!) {
  updateOneTypesettingParamWarning(input: $input) {
    typesettingParamWarning {
      ...TypesettingParamWarningFragment
    }
  }
}
    ${TypesettingParamWarningFragmentFragmentDoc}`;

/**
 * __useUpdateOneTypesettingParamWarningMutation__
 *
 * To run a mutation, you first call `useUpdateOneTypesettingParamWarningMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTypesettingParamWarningMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneTypesettingParamWarningMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneTypesettingParamWarningMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamWarningMutation, schema.UpdateOneTypesettingParamWarningMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneTypesettingParamWarningMutation, schema.UpdateOneTypesettingParamWarningMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneTypesettingParamWarningMutation, schema.UpdateOneTypesettingParamWarningMutationVariables>(UpdateOneTypesettingParamWarningDocument, options);
}
export type UpdateOneTypesettingParamWarningMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneTypesettingParamWarningMutation, schema.UpdateOneTypesettingParamWarningMutationVariables>;
export const GetManyInnerCartonParamDocument = gql`
    query GetManyInnerCartonParam {
  innerCartonParams {
    ...InnerCartonParamFragment
  }
}
    ${InnerCartonParamFragmentFragmentDoc}`;

/**
 * __useGetManyInnerCartonParamQuery__
 *
 * To run a query within a Vue component, call `useGetManyInnerCartonParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyInnerCartonParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyInnerCartonParamQuery();
 */
export function useGetManyInnerCartonParamQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyInnerCartonParamQuery, schema.GetManyInnerCartonParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyInnerCartonParamQuery, schema.GetManyInnerCartonParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyInnerCartonParamQuery, schema.GetManyInnerCartonParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyInnerCartonParamQuery, schema.GetManyInnerCartonParamQueryVariables>(GetManyInnerCartonParamDocument, {}, options);
}
export type GetManyInnerCartonParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyInnerCartonParamQuery, schema.GetManyInnerCartonParamQueryVariables>;
export const GetOneInnerCartonParamDocument = gql`
    query GetOneInnerCartonParam($id: ID!) {
  innerCartonParam(id: $id) {
    ...InnerCartonParamFragment
  }
}
    ${InnerCartonParamFragmentFragmentDoc}`;

/**
 * __useGetOneInnerCartonParamQuery__
 *
 * To run a query within a Vue component, call `useGetOneInnerCartonParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneInnerCartonParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneInnerCartonParamQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneInnerCartonParamQuery(variables: schema.GetOneInnerCartonParamQueryVariables | VueCompositionApi.Ref<schema.GetOneInnerCartonParamQueryVariables> | ReactiveFunction<schema.GetOneInnerCartonParamQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneInnerCartonParamQuery, schema.GetOneInnerCartonParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneInnerCartonParamQuery, schema.GetOneInnerCartonParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneInnerCartonParamQuery, schema.GetOneInnerCartonParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneInnerCartonParamQuery, schema.GetOneInnerCartonParamQueryVariables>(GetOneInnerCartonParamDocument, variables, options);
}
export type GetOneInnerCartonParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneInnerCartonParamQuery, schema.GetOneInnerCartonParamQueryVariables>;
export const GetManyClientApplicationFlowDocument = gql`
    query GetManyClientApplicationFlow($clientUserGroupId: ID!) {
  clientApplicationFlows(clientUserGroupId: $clientUserGroupId) {
    ...ClientApplicationFlowFragment
    clientUserGroup {
      ...ClientUserGroupFragment
    }
    routes {
      ...ClientApplicationFlowRouteFragment
      levels {
        ...ClientApplicationFlowLevelFragment
      }
    }
  }
}
    ${ClientApplicationFlowFragmentFragmentDoc}
${ClientUserGroupFragmentFragmentDoc}
${ClientApplicationFlowRouteFragmentFragmentDoc}
${ClientApplicationFlowLevelFragmentFragmentDoc}`;

/**
 * __useGetManyClientApplicationFlowQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientApplicationFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientApplicationFlowQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientApplicationFlowQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 * });
 */
export function useGetManyClientApplicationFlowQuery(variables: schema.GetManyClientApplicationFlowQueryVariables | VueCompositionApi.Ref<schema.GetManyClientApplicationFlowQueryVariables> | ReactiveFunction<schema.GetManyClientApplicationFlowQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowQuery, schema.GetManyClientApplicationFlowQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowQuery, schema.GetManyClientApplicationFlowQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowQuery, schema.GetManyClientApplicationFlowQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientApplicationFlowQuery, schema.GetManyClientApplicationFlowQueryVariables>(GetManyClientApplicationFlowDocument, variables, options);
}
export type GetManyClientApplicationFlowQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientApplicationFlowQuery, schema.GetManyClientApplicationFlowQueryVariables>;
export const GetOneClientApplicationFlowDocument = gql`
    query GetOneClientApplicationFlow($id: ID!) {
  clientApplicationFlow(id: $id) {
    ...ClientApplicationFlowFragment
    clientUserGroup {
      ...ClientUserGroupFragment
      members {
        ...ClientUserFragment
      }
    }
    routes {
      ...ClientApplicationFlowRouteFragment
      levels {
        ...ClientApplicationFlowLevelFragment
      }
    }
  }
}
    ${ClientApplicationFlowFragmentFragmentDoc}
${ClientUserGroupFragmentFragmentDoc}
${ClientUserFragmentFragmentDoc}
${ClientApplicationFlowRouteFragmentFragmentDoc}
${ClientApplicationFlowLevelFragmentFragmentDoc}`;

/**
 * __useGetOneClientApplicationFlowQuery__
 *
 * To run a query within a Vue component, call `useGetOneClientApplicationFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientApplicationFlowQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneClientApplicationFlowQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneClientApplicationFlowQuery(variables: schema.GetOneClientApplicationFlowQueryVariables | VueCompositionApi.Ref<schema.GetOneClientApplicationFlowQueryVariables> | ReactiveFunction<schema.GetOneClientApplicationFlowQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowQuery, schema.GetOneClientApplicationFlowQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowQuery, schema.GetOneClientApplicationFlowQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowQuery, schema.GetOneClientApplicationFlowQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneClientApplicationFlowQuery, schema.GetOneClientApplicationFlowQueryVariables>(GetOneClientApplicationFlowDocument, variables, options);
}
export type GetOneClientApplicationFlowQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneClientApplicationFlowQuery, schema.GetOneClientApplicationFlowQueryVariables>;
export const GetManyClientApplicationFlowLevelDocument = gql`
    query GetManyClientApplicationFlowLevel($routeId: ID!) {
  clientApplicationFlowLevels(routeId: $routeId) {
    ...ClientApplicationFlowLevelFragment
  }
}
    ${ClientApplicationFlowLevelFragmentFragmentDoc}`;

/**
 * __useGetManyClientApplicationFlowLevelQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientApplicationFlowLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientApplicationFlowLevelQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientApplicationFlowLevelQuery({
 *   routeId: // value for 'routeId'
 * });
 */
export function useGetManyClientApplicationFlowLevelQuery(variables: schema.GetManyClientApplicationFlowLevelQueryVariables | VueCompositionApi.Ref<schema.GetManyClientApplicationFlowLevelQueryVariables> | ReactiveFunction<schema.GetManyClientApplicationFlowLevelQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowLevelQuery, schema.GetManyClientApplicationFlowLevelQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowLevelQuery, schema.GetManyClientApplicationFlowLevelQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowLevelQuery, schema.GetManyClientApplicationFlowLevelQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientApplicationFlowLevelQuery, schema.GetManyClientApplicationFlowLevelQueryVariables>(GetManyClientApplicationFlowLevelDocument, variables, options);
}
export type GetManyClientApplicationFlowLevelQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientApplicationFlowLevelQuery, schema.GetManyClientApplicationFlowLevelQueryVariables>;
export const GetOneClientApplicationFlowLevelDocument = gql`
    query GetOneClientApplicationFlowLevel($id: ID!) {
  clientApplicationFlowLevel(id: $id) {
    ...ClientApplicationFlowLevelFragment
  }
}
    ${ClientApplicationFlowLevelFragmentFragmentDoc}`;

/**
 * __useGetOneClientApplicationFlowLevelQuery__
 *
 * To run a query within a Vue component, call `useGetOneClientApplicationFlowLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientApplicationFlowLevelQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneClientApplicationFlowLevelQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneClientApplicationFlowLevelQuery(variables: schema.GetOneClientApplicationFlowLevelQueryVariables | VueCompositionApi.Ref<schema.GetOneClientApplicationFlowLevelQueryVariables> | ReactiveFunction<schema.GetOneClientApplicationFlowLevelQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowLevelQuery, schema.GetOneClientApplicationFlowLevelQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowLevelQuery, schema.GetOneClientApplicationFlowLevelQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowLevelQuery, schema.GetOneClientApplicationFlowLevelQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneClientApplicationFlowLevelQuery, schema.GetOneClientApplicationFlowLevelQueryVariables>(GetOneClientApplicationFlowLevelDocument, variables, options);
}
export type GetOneClientApplicationFlowLevelQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneClientApplicationFlowLevelQuery, schema.GetOneClientApplicationFlowLevelQueryVariables>;
export const GetManyClientApplicationFlowRouteDocument = gql`
    query GetManyClientApplicationFlowRoute($flowId: ID!) {
  clientApplicationFlowRoutes(flowId: $flowId) {
    ...ClientApplicationFlowRouteFragment
  }
}
    ${ClientApplicationFlowRouteFragmentFragmentDoc}`;

/**
 * __useGetManyClientApplicationFlowRouteQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientApplicationFlowRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientApplicationFlowRouteQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientApplicationFlowRouteQuery({
 *   flowId: // value for 'flowId'
 * });
 */
export function useGetManyClientApplicationFlowRouteQuery(variables: schema.GetManyClientApplicationFlowRouteQueryVariables | VueCompositionApi.Ref<schema.GetManyClientApplicationFlowRouteQueryVariables> | ReactiveFunction<schema.GetManyClientApplicationFlowRouteQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowRouteQuery, schema.GetManyClientApplicationFlowRouteQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowRouteQuery, schema.GetManyClientApplicationFlowRouteQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientApplicationFlowRouteQuery, schema.GetManyClientApplicationFlowRouteQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientApplicationFlowRouteQuery, schema.GetManyClientApplicationFlowRouteQueryVariables>(GetManyClientApplicationFlowRouteDocument, variables, options);
}
export type GetManyClientApplicationFlowRouteQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientApplicationFlowRouteQuery, schema.GetManyClientApplicationFlowRouteQueryVariables>;
export const GetOneClientApplicationFlowRouteDocument = gql`
    query GetOneClientApplicationFlowRoute($id: ID!) {
  clientApplicationFlowRoute(id: $id) {
    ...ClientApplicationFlowRouteFragment
  }
}
    ${ClientApplicationFlowRouteFragmentFragmentDoc}`;

/**
 * __useGetOneClientApplicationFlowRouteQuery__
 *
 * To run a query within a Vue component, call `useGetOneClientApplicationFlowRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientApplicationFlowRouteQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneClientApplicationFlowRouteQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneClientApplicationFlowRouteQuery(variables: schema.GetOneClientApplicationFlowRouteQueryVariables | VueCompositionApi.Ref<schema.GetOneClientApplicationFlowRouteQueryVariables> | ReactiveFunction<schema.GetOneClientApplicationFlowRouteQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowRouteQuery, schema.GetOneClientApplicationFlowRouteQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowRouteQuery, schema.GetOneClientApplicationFlowRouteQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneClientApplicationFlowRouteQuery, schema.GetOneClientApplicationFlowRouteQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneClientApplicationFlowRouteQuery, schema.GetOneClientApplicationFlowRouteQueryVariables>(GetOneClientApplicationFlowRouteDocument, variables, options);
}
export type GetOneClientApplicationFlowRouteQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneClientApplicationFlowRouteQuery, schema.GetOneClientApplicationFlowRouteQueryVariables>;
export const GetManyClientContactDocument = gql`
    query GetManyClientContact($clientUserGroupId: ID, $offset: Int, $limit: Int) {
  clientContacts(
    clientUserGroupId: $clientUserGroupId
    offset: $offset
    limit: $limit
  ) {
    ...ClientContactFragment
  }
}
    ${ClientContactFragmentFragmentDoc}`;

/**
 * __useGetManyClientContactQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientContactQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientContactQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useGetManyClientContactQuery(variables: schema.GetManyClientContactQueryVariables | VueCompositionApi.Ref<schema.GetManyClientContactQueryVariables> | ReactiveFunction<schema.GetManyClientContactQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyClientContactQuery, schema.GetManyClientContactQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientContactQuery, schema.GetManyClientContactQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientContactQuery, schema.GetManyClientContactQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientContactQuery, schema.GetManyClientContactQueryVariables>(GetManyClientContactDocument, variables, options);
}
export type GetManyClientContactQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientContactQuery, schema.GetManyClientContactQueryVariables>;
export const GetManyCountClientContactDocument = gql`
    query GetManyCountClientContact($clientUserGroupId: ID) {
  clientContactsCount(clientUserGroupId: $clientUserGroupId)
}
    `;

/**
 * __useGetManyCountClientContactQuery__
 *
 * To run a query within a Vue component, call `useGetManyCountClientContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCountClientContactQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyCountClientContactQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 * });
 */
export function useGetManyCountClientContactQuery(variables: schema.GetManyCountClientContactQueryVariables | VueCompositionApi.Ref<schema.GetManyCountClientContactQueryVariables> | ReactiveFunction<schema.GetManyCountClientContactQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyCountClientContactQuery, schema.GetManyCountClientContactQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientContactQuery, schema.GetManyCountClientContactQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientContactQuery, schema.GetManyCountClientContactQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyCountClientContactQuery, schema.GetManyCountClientContactQueryVariables>(GetManyCountClientContactDocument, variables, options);
}
export type GetManyCountClientContactQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyCountClientContactQuery, schema.GetManyCountClientContactQueryVariables>;
export const GetManyClientDeliveryDocument = gql`
    query GetManyClientDelivery($clientUserGroupId: ID, $offset: Int, $limit: Int) {
  clientDeliveries(
    clientUserGroupId: $clientUserGroupId
    offset: $offset
    limit: $limit
  ) {
    ...ClientDeliveryFragment
  }
}
    ${ClientDeliveryFragmentFragmentDoc}`;

/**
 * __useGetManyClientDeliveryQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientDeliveryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientDeliveryQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useGetManyClientDeliveryQuery(variables: schema.GetManyClientDeliveryQueryVariables | VueCompositionApi.Ref<schema.GetManyClientDeliveryQueryVariables> | ReactiveFunction<schema.GetManyClientDeliveryQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyClientDeliveryQuery, schema.GetManyClientDeliveryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientDeliveryQuery, schema.GetManyClientDeliveryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientDeliveryQuery, schema.GetManyClientDeliveryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientDeliveryQuery, schema.GetManyClientDeliveryQueryVariables>(GetManyClientDeliveryDocument, variables, options);
}
export type GetManyClientDeliveryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientDeliveryQuery, schema.GetManyClientDeliveryQueryVariables>;
export const GetManyCountClientDeliveryDocument = gql`
    query GetManyCountClientDelivery($clientUserGroupId: ID) {
  clientDeliveriesCount(clientUserGroupId: $clientUserGroupId)
}
    `;

/**
 * __useGetManyCountClientDeliveryQuery__
 *
 * To run a query within a Vue component, call `useGetManyCountClientDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCountClientDeliveryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyCountClientDeliveryQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 * });
 */
export function useGetManyCountClientDeliveryQuery(variables: schema.GetManyCountClientDeliveryQueryVariables | VueCompositionApi.Ref<schema.GetManyCountClientDeliveryQueryVariables> | ReactiveFunction<schema.GetManyCountClientDeliveryQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyCountClientDeliveryQuery, schema.GetManyCountClientDeliveryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientDeliveryQuery, schema.GetManyCountClientDeliveryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientDeliveryQuery, schema.GetManyCountClientDeliveryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyCountClientDeliveryQuery, schema.GetManyCountClientDeliveryQueryVariables>(GetManyCountClientDeliveryDocument, variables, options);
}
export type GetManyCountClientDeliveryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyCountClientDeliveryQuery, schema.GetManyCountClientDeliveryQueryVariables>;
export const GetManyClientShipperDocument = gql`
    query GetManyClientShipper($clientUserGroupId: ID, $offset: Int, $limit: Int) {
  clientShippers(
    clientUserGroupId: $clientUserGroupId
    offset: $offset
    limit: $limit
  ) {
    ...ClientShipperFragment
  }
}
    ${ClientShipperFragmentFragmentDoc}`;

/**
 * __useGetManyClientShipperQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientShipperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientShipperQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientShipperQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useGetManyClientShipperQuery(variables: schema.GetManyClientShipperQueryVariables | VueCompositionApi.Ref<schema.GetManyClientShipperQueryVariables> | ReactiveFunction<schema.GetManyClientShipperQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyClientShipperQuery, schema.GetManyClientShipperQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientShipperQuery, schema.GetManyClientShipperQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientShipperQuery, schema.GetManyClientShipperQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientShipperQuery, schema.GetManyClientShipperQueryVariables>(GetManyClientShipperDocument, variables, options);
}
export type GetManyClientShipperQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientShipperQuery, schema.GetManyClientShipperQueryVariables>;
export const GetManyCountClientShipperDocument = gql`
    query GetManyCountClientShipper($clientUserGroupId: ID) {
  clientShippersCount(clientUserGroupId: $clientUserGroupId)
}
    `;

/**
 * __useGetManyCountClientShipperQuery__
 *
 * To run a query within a Vue component, call `useGetManyCountClientShipperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCountClientShipperQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyCountClientShipperQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 * });
 */
export function useGetManyCountClientShipperQuery(variables: schema.GetManyCountClientShipperQueryVariables | VueCompositionApi.Ref<schema.GetManyCountClientShipperQueryVariables> | ReactiveFunction<schema.GetManyCountClientShipperQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyCountClientShipperQuery, schema.GetManyCountClientShipperQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientShipperQuery, schema.GetManyCountClientShipperQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientShipperQuery, schema.GetManyCountClientShipperQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyCountClientShipperQuery, schema.GetManyCountClientShipperQueryVariables>(GetManyCountClientShipperDocument, variables, options);
}
export type GetManyCountClientShipperQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyCountClientShipperQuery, schema.GetManyCountClientShipperQueryVariables>;
export const GetManyClientStorageFileDocument = gql`
    query GetManyClientStorageFile($clientUserGroupId: ID, $offset: Int, $limit: Int) {
  clientStorageFiles(
    clientUserGroupId: $clientUserGroupId
    offset: $offset
    limit: $limit
  ) {
    ...ClientStorageFileFragment
  }
}
    ${ClientStorageFileFragmentFragmentDoc}`;

/**
 * __useGetManyClientStorageFileQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientStorageFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientStorageFileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientStorageFileQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useGetManyClientStorageFileQuery(variables: schema.GetManyClientStorageFileQueryVariables | VueCompositionApi.Ref<schema.GetManyClientStorageFileQueryVariables> | ReactiveFunction<schema.GetManyClientStorageFileQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyClientStorageFileQuery, schema.GetManyClientStorageFileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientStorageFileQuery, schema.GetManyClientStorageFileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientStorageFileQuery, schema.GetManyClientStorageFileQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientStorageFileQuery, schema.GetManyClientStorageFileQueryVariables>(GetManyClientStorageFileDocument, variables, options);
}
export type GetManyClientStorageFileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientStorageFileQuery, schema.GetManyClientStorageFileQueryVariables>;
export const GetManyCountClientStorageFileDocument = gql`
    query GetManyCountClientStorageFile($clientUserGroupId: ID) {
  clientStorageFilesCount(clientUserGroupId: $clientUserGroupId)
}
    `;

/**
 * __useGetManyCountClientStorageFileQuery__
 *
 * To run a query within a Vue component, call `useGetManyCountClientStorageFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCountClientStorageFileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyCountClientStorageFileQuery({
 *   clientUserGroupId: // value for 'clientUserGroupId'
 * });
 */
export function useGetManyCountClientStorageFileQuery(variables: schema.GetManyCountClientStorageFileQueryVariables | VueCompositionApi.Ref<schema.GetManyCountClientStorageFileQueryVariables> | ReactiveFunction<schema.GetManyCountClientStorageFileQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyCountClientStorageFileQuery, schema.GetManyCountClientStorageFileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientStorageFileQuery, schema.GetManyCountClientStorageFileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyCountClientStorageFileQuery, schema.GetManyCountClientStorageFileQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyCountClientStorageFileQuery, schema.GetManyCountClientStorageFileQueryVariables>(GetManyCountClientStorageFileDocument, variables, options);
}
export type GetManyCountClientStorageFileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyCountClientStorageFileQuery, schema.GetManyCountClientStorageFileQueryVariables>;
export const GetOneClientStorageFileDocument = gql`
    query GetOneClientStorageFile($id: ID!) {
  clientStorageFile(id: $id) {
    ...ClientStorageFileFragment
  }
}
    ${ClientStorageFileFragmentFragmentDoc}`;

/**
 * __useGetOneClientStorageFileQuery__
 *
 * To run a query within a Vue component, call `useGetOneClientStorageFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientStorageFileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneClientStorageFileQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneClientStorageFileQuery(variables: schema.GetOneClientStorageFileQueryVariables | VueCompositionApi.Ref<schema.GetOneClientStorageFileQueryVariables> | ReactiveFunction<schema.GetOneClientStorageFileQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneClientStorageFileQuery, schema.GetOneClientStorageFileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneClientStorageFileQuery, schema.GetOneClientStorageFileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneClientStorageFileQuery, schema.GetOneClientStorageFileQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneClientStorageFileQuery, schema.GetOneClientStorageFileQueryVariables>(GetOneClientStorageFileDocument, variables, options);
}
export type GetOneClientStorageFileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneClientStorageFileQuery, schema.GetOneClientStorageFileQueryVariables>;
export const GetOneClientStorageSummaryDocument = gql`
    query GetOneClientStorageSummary {
  clientStorageSummary {
    ...ClientStorageSummaryFragment
  }
}
    ${ClientStorageSummaryFragmentFragmentDoc}`;

/**
 * __useGetOneClientStorageSummaryQuery__
 *
 * To run a query within a Vue component, call `useGetOneClientStorageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientStorageSummaryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneClientStorageSummaryQuery();
 */
export function useGetOneClientStorageSummaryQuery(options: VueApolloComposable.UseQueryOptions<schema.GetOneClientStorageSummaryQuery, schema.GetOneClientStorageSummaryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneClientStorageSummaryQuery, schema.GetOneClientStorageSummaryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneClientStorageSummaryQuery, schema.GetOneClientStorageSummaryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneClientStorageSummaryQuery, schema.GetOneClientStorageSummaryQueryVariables>(GetOneClientStorageSummaryDocument, {}, options);
}
export type GetOneClientStorageSummaryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneClientStorageSummaryQuery, schema.GetOneClientStorageSummaryQueryVariables>;
export const GetManyClientUserDocument = gql`
    query GetManyClientUser {
  clientUsers {
    ...ClientUserFragment
  }
}
    ${ClientUserFragmentFragmentDoc}`;

/**
 * __useGetManyClientUserQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientUserQuery();
 */
export function useGetManyClientUserQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyClientUserQuery, schema.GetManyClientUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientUserQuery, schema.GetManyClientUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientUserQuery, schema.GetManyClientUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientUserQuery, schema.GetManyClientUserQueryVariables>(GetManyClientUserDocument, {}, options);
}
export type GetManyClientUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientUserQuery, schema.GetManyClientUserQueryVariables>;
export const GetOneClientUserDocument = gql`
    query GetOneClientUser($id: ID!) {
  clientUser(id: $id) {
    ...ClientUserFragment
    userGroups {
      ...ClientUserGroupFragment
    }
  }
}
    ${ClientUserFragmentFragmentDoc}
${ClientUserGroupFragmentFragmentDoc}`;

/**
 * __useGetOneClientUserQuery__
 *
 * To run a query within a Vue component, call `useGetOneClientUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneClientUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneClientUserQuery(variables: schema.GetOneClientUserQueryVariables | VueCompositionApi.Ref<schema.GetOneClientUserQueryVariables> | ReactiveFunction<schema.GetOneClientUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneClientUserQuery, schema.GetOneClientUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneClientUserQuery, schema.GetOneClientUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneClientUserQuery, schema.GetOneClientUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneClientUserQuery, schema.GetOneClientUserQueryVariables>(GetOneClientUserDocument, variables, options);
}
export type GetOneClientUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneClientUserQuery, schema.GetOneClientUserQueryVariables>;
export const GetManyClientUserGroupDocument = gql`
    query GetManyClientUserGroup {
  clientUserGroups {
    ...ClientUserGroupFragment
    members {
      ...ClientUserFragment
    }
    children {
      ...ClientUserGroupFragment
      members {
        ...ClientUserFragment
      }
    }
  }
}
    ${ClientUserGroupFragmentFragmentDoc}
${ClientUserFragmentFragmentDoc}`;

/**
 * __useGetManyClientUserGroupQuery__
 *
 * To run a query within a Vue component, call `useGetManyClientUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClientUserGroupQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyClientUserGroupQuery();
 */
export function useGetManyClientUserGroupQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyClientUserGroupQuery, schema.GetManyClientUserGroupQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyClientUserGroupQuery, schema.GetManyClientUserGroupQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyClientUserGroupQuery, schema.GetManyClientUserGroupQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyClientUserGroupQuery, schema.GetManyClientUserGroupQueryVariables>(GetManyClientUserGroupDocument, {}, options);
}
export type GetManyClientUserGroupQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyClientUserGroupQuery, schema.GetManyClientUserGroupQueryVariables>;
export const GetOneClientUserGroupDocument = gql`
    query GetOneClientUserGroup($id: ID!) {
  clientUserGroup(id: $id) {
    ...ClientUserGroupFragment
  }
}
    ${ClientUserGroupFragmentFragmentDoc}`;

/**
 * __useGetOneClientUserGroupQuery__
 *
 * To run a query within a Vue component, call `useGetOneClientUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientUserGroupQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneClientUserGroupQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneClientUserGroupQuery(variables: schema.GetOneClientUserGroupQueryVariables | VueCompositionApi.Ref<schema.GetOneClientUserGroupQueryVariables> | ReactiveFunction<schema.GetOneClientUserGroupQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneClientUserGroupQuery, schema.GetOneClientUserGroupQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneClientUserGroupQuery, schema.GetOneClientUserGroupQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneClientUserGroupQuery, schema.GetOneClientUserGroupQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneClientUserGroupQuery, schema.GetOneClientUserGroupQueryVariables>(GetOneClientUserGroupDocument, variables, options);
}
export type GetOneClientUserGroupQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneClientUserGroupQuery, schema.GetOneClientUserGroupQueryVariables>;
export const GetManyHolidayDocument = gql`
    query GetManyHoliday {
  holidays {
    ...HolidayFragment
  }
}
    ${HolidayFragmentFragmentDoc}`;

/**
 * __useGetManyHolidayQuery__
 *
 * To run a query within a Vue component, call `useGetManyHolidayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyHolidayQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyHolidayQuery();
 */
export function useGetManyHolidayQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>(GetManyHolidayDocument, {}, options);
}
export type GetManyHolidayQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>;
export const GetManyNotificationDocument = gql`
    query GetManyNotification {
  notifications {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetManyNotificationQuery__
 *
 * To run a query within a Vue component, call `useGetManyNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyNotificationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyNotificationQuery();
 */
export function useGetManyNotificationQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>(GetManyNotificationDocument, {}, options);
}
export type GetManyNotificationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>;
export const GetOneNotificationDocument = gql`
    query GetOneNotification($id: ID!) {
  notification(id: $id) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetOneNotificationQuery__
 *
 * To run a query within a Vue component, call `useGetOneNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneNotificationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneNotificationQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneNotificationQuery(variables: schema.GetOneNotificationQueryVariables | VueCompositionApi.Ref<schema.GetOneNotificationQueryVariables> | ReactiveFunction<schema.GetOneNotificationQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>(GetOneNotificationDocument, variables, options);
}
export type GetOneNotificationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>;
export const GetAmountOrderDocument = gql`
    query GetAmountOrder($clientUserId: ID, $draftOnly: Boolean, $orderedFrom: ISO8601DateTime, $orderedTo: ISO8601DateTime, $orderNo: String, $productName: String, $orderStatusId: ID, $productCategoryId: ID, $productTagIds: [ID!], $offset: Int, $limit: Int) {
  ordersAmount(
    clientUserId: $clientUserId
    draftOnly: $draftOnly
    orderedFrom: $orderedFrom
    orderedTo: $orderedTo
    orderNo: $orderNo
    productName: $productName
    orderStatusId: $orderStatusId
    productCategoryId: $productCategoryId
    productTagIds: $productTagIds
    offset: $offset
    limit: $limit
  )
}
    `;

/**
 * __useGetAmountOrderQuery__
 *
 * To run a query within a Vue component, call `useGetAmountOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmountOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAmountOrderQuery({
 *   clientUserId: // value for 'clientUserId'
 *   draftOnly: // value for 'draftOnly'
 *   orderedFrom: // value for 'orderedFrom'
 *   orderedTo: // value for 'orderedTo'
 *   orderNo: // value for 'orderNo'
 *   productName: // value for 'productName'
 *   orderStatusId: // value for 'orderStatusId'
 *   productCategoryId: // value for 'productCategoryId'
 *   productTagIds: // value for 'productTagIds'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useGetAmountOrderQuery(variables: schema.GetAmountOrderQueryVariables | VueCompositionApi.Ref<schema.GetAmountOrderQueryVariables> | ReactiveFunction<schema.GetAmountOrderQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetAmountOrderQuery, schema.GetAmountOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetAmountOrderQuery, schema.GetAmountOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetAmountOrderQuery, schema.GetAmountOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetAmountOrderQuery, schema.GetAmountOrderQueryVariables>(GetAmountOrderDocument, variables, options);
}
export type GetAmountOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetAmountOrderQuery, schema.GetAmountOrderQueryVariables>;
export const GetManyOrderDocument = gql`
    query GetManyOrder($clientUserId: ID, $draftOnly: Boolean, $parentOnly: Boolean, $orderedFrom: ISO8601DateTime, $orderedTo: ISO8601DateTime, $orderNo: String, $productName: String, $orderStatusId: ID, $productCategoryId: ID, $productTagIds: [ID!], $offset: Int, $limit: Int) {
  orders(
    clientUserId: $clientUserId
    draftOnly: $draftOnly
    parentOnly: $parentOnly
    orderedFrom: $orderedFrom
    orderedTo: $orderedTo
    orderNo: $orderNo
    productName: $productName
    orderStatusId: $orderStatusId
    productCategoryId: $productCategoryId
    productTagIds: $productTagIds
    offset: $offset
    limit: $limit
  ) {
    ...OrderFragment
    children {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useGetManyOrderQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderQuery({
 *   clientUserId: // value for 'clientUserId'
 *   draftOnly: // value for 'draftOnly'
 *   parentOnly: // value for 'parentOnly'
 *   orderedFrom: // value for 'orderedFrom'
 *   orderedTo: // value for 'orderedTo'
 *   orderNo: // value for 'orderNo'
 *   productName: // value for 'productName'
 *   orderStatusId: // value for 'orderStatusId'
 *   productCategoryId: // value for 'productCategoryId'
 *   productTagIds: // value for 'productTagIds'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useGetManyOrderQuery(variables: schema.GetManyOrderQueryVariables | VueCompositionApi.Ref<schema.GetManyOrderQueryVariables> | ReactiveFunction<schema.GetManyOrderQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>(GetManyOrderDocument, variables, options);
}
export type GetManyOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>;
export const GetManyCountOrderDocument = gql`
    query GetManyCountOrder($clientUserId: ID, $draftOnly: Boolean, $parentOnly: Boolean, $orderedFrom: ISO8601DateTime, $orderedTo: ISO8601DateTime, $orderNo: String, $productName: String, $orderStatusId: ID, $productCategoryId: ID, $productTagIds: [ID!]) {
  ordersCount(
    clientUserId: $clientUserId
    draftOnly: $draftOnly
    parentOnly: $parentOnly
    orderedFrom: $orderedFrom
    orderedTo: $orderedTo
    orderNo: $orderNo
    productName: $productName
    orderStatusId: $orderStatusId
    productCategoryId: $productCategoryId
    productTagIds: $productTagIds
  )
}
    `;

/**
 * __useGetManyCountOrderQuery__
 *
 * To run a query within a Vue component, call `useGetManyCountOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCountOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyCountOrderQuery({
 *   clientUserId: // value for 'clientUserId'
 *   draftOnly: // value for 'draftOnly'
 *   parentOnly: // value for 'parentOnly'
 *   orderedFrom: // value for 'orderedFrom'
 *   orderedTo: // value for 'orderedTo'
 *   orderNo: // value for 'orderNo'
 *   productName: // value for 'productName'
 *   orderStatusId: // value for 'orderStatusId'
 *   productCategoryId: // value for 'productCategoryId'
 *   productTagIds: // value for 'productTagIds'
 * });
 */
export function useGetManyCountOrderQuery(variables: schema.GetManyCountOrderQueryVariables | VueCompositionApi.Ref<schema.GetManyCountOrderQueryVariables> | ReactiveFunction<schema.GetManyCountOrderQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyCountOrderQuery, schema.GetManyCountOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyCountOrderQuery, schema.GetManyCountOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyCountOrderQuery, schema.GetManyCountOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyCountOrderQuery, schema.GetManyCountOrderQueryVariables>(GetManyCountOrderDocument, variables, options);
}
export type GetManyCountOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyCountOrderQuery, schema.GetManyCountOrderQueryVariables>;
export const GetOneOrderDocument = gql`
    query GetOneOrder($id: ID!) {
  order(id: $id) {
    ...OrderFragment
    children {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useGetOneOrderQuery__
 *
 * To run a query within a Vue component, call `useGetOneOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneOrderQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneOrderQuery(variables: schema.GetOneOrderQueryVariables | VueCompositionApi.Ref<schema.GetOneOrderQueryVariables> | ReactiveFunction<schema.GetOneOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>(GetOneOrderDocument, variables, options);
}
export type GetOneOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>;
export const GetTotalOrderDocument = gql`
    query GetTotalOrder($clientUserId: ID, $draftOnly: Boolean, $orderedFrom: ISO8601DateTime, $orderedTo: ISO8601DateTime, $orderNo: String, $productName: String, $orderStatusId: ID, $productCategoryId: ID, $productTagIds: [ID!], $offset: Int, $limit: Int) {
  ordersTotal(
    clientUserId: $clientUserId
    draftOnly: $draftOnly
    orderedFrom: $orderedFrom
    orderedTo: $orderedTo
    orderNo: $orderNo
    productName: $productName
    orderStatusId: $orderStatusId
    productCategoryId: $productCategoryId
    productTagIds: $productTagIds
    offset: $offset
    limit: $limit
  )
}
    `;

/**
 * __useGetTotalOrderQuery__
 *
 * To run a query within a Vue component, call `useGetTotalOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetTotalOrderQuery({
 *   clientUserId: // value for 'clientUserId'
 *   draftOnly: // value for 'draftOnly'
 *   orderedFrom: // value for 'orderedFrom'
 *   orderedTo: // value for 'orderedTo'
 *   orderNo: // value for 'orderNo'
 *   productName: // value for 'productName'
 *   orderStatusId: // value for 'orderStatusId'
 *   productCategoryId: // value for 'productCategoryId'
 *   productTagIds: // value for 'productTagIds'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useGetTotalOrderQuery(variables: schema.GetTotalOrderQueryVariables | VueCompositionApi.Ref<schema.GetTotalOrderQueryVariables> | ReactiveFunction<schema.GetTotalOrderQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetTotalOrderQuery, schema.GetTotalOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetTotalOrderQuery, schema.GetTotalOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetTotalOrderQuery, schema.GetTotalOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetTotalOrderQuery, schema.GetTotalOrderQueryVariables>(GetTotalOrderDocument, variables, options);
}
export type GetTotalOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetTotalOrderQuery, schema.GetTotalOrderQueryVariables>;
export const GetManyOrderApprovalRecordDocument = gql`
    query GetManyOrderApprovalRecord($current: Boolean, $parentOnly: Boolean) {
  orderApprovalRecords(current: $current, parentOnly: $parentOnly) {
    ...OrderApprovalRecordFragment
    children {
      ...OrderApprovalRecordFragment
    }
  }
}
    ${OrderApprovalRecordFragmentFragmentDoc}`;

/**
 * __useGetManyOrderApprovalRecordQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderApprovalRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderApprovalRecordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderApprovalRecordQuery({
 *   current: // value for 'current'
 *   parentOnly: // value for 'parentOnly'
 * });
 */
export function useGetManyOrderApprovalRecordQuery(variables: schema.GetManyOrderApprovalRecordQueryVariables | VueCompositionApi.Ref<schema.GetManyOrderApprovalRecordQueryVariables> | ReactiveFunction<schema.GetManyOrderApprovalRecordQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderApprovalRecordQuery, schema.GetManyOrderApprovalRecordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderApprovalRecordQuery, schema.GetManyOrderApprovalRecordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderApprovalRecordQuery, schema.GetManyOrderApprovalRecordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderApprovalRecordQuery, schema.GetManyOrderApprovalRecordQueryVariables>(GetManyOrderApprovalRecordDocument, variables, options);
}
export type GetManyOrderApprovalRecordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderApprovalRecordQuery, schema.GetManyOrderApprovalRecordQueryVariables>;
export const GetManyOrderCommentDocument = gql`
    query GetManyOrderComment($orderId: ID!) {
  orderComments(orderId: $orderId) {
    ...OrderCommentFragment
  }
}
    ${OrderCommentFragmentFragmentDoc}`;

/**
 * __useGetManyOrderCommentQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderCommentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderCommentQuery({
 *   orderId: // value for 'orderId'
 * });
 */
export function useGetManyOrderCommentQuery(variables: schema.GetManyOrderCommentQueryVariables | VueCompositionApi.Ref<schema.GetManyOrderCommentQueryVariables> | ReactiveFunction<schema.GetManyOrderCommentQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>(GetManyOrderCommentDocument, variables, options);
}
export type GetManyOrderCommentQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>;
export const GetManyOrderProductFileDocument = gql`
    query GetManyOrderProductFile($orderId: ID!) {
  orderProductFiles(orderId: $orderId) {
    ...OrderProductFileFragment
  }
}
    ${OrderProductFileFragmentFragmentDoc}`;

/**
 * __useGetManyOrderProductFileQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderProductFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderProductFileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderProductFileQuery({
 *   orderId: // value for 'orderId'
 * });
 */
export function useGetManyOrderProductFileQuery(variables: schema.GetManyOrderProductFileQueryVariables | VueCompositionApi.Ref<schema.GetManyOrderProductFileQueryVariables> | ReactiveFunction<schema.GetManyOrderProductFileQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderProductFileQuery, schema.GetManyOrderProductFileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderProductFileQuery, schema.GetManyOrderProductFileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderProductFileQuery, schema.GetManyOrderProductFileQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderProductFileQuery, schema.GetManyOrderProductFileQueryVariables>(GetManyOrderProductFileDocument, variables, options);
}
export type GetManyOrderProductFileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderProductFileQuery, schema.GetManyOrderProductFileQueryVariables>;
export const GetManyOrderStatusDocument = gql`
    query GetManyOrderStatus {
  orderStatuses {
    ...OrderStatusFragment
  }
}
    ${OrderStatusFragmentFragmentDoc}`;

/**
 * __useGetManyOrderStatusQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderStatusQuery();
 */
export function useGetManyOrderStatusQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>(GetManyOrderStatusDocument, {}, options);
}
export type GetManyOrderStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>;
export const GetManyOuterCartonParamDocument = gql`
    query GetManyOuterCartonParam {
  outerCartonParams {
    ...OuterCartonParamFragment
  }
}
    ${OuterCartonParamFragmentFragmentDoc}`;

/**
 * __useGetManyOuterCartonParamQuery__
 *
 * To run a query within a Vue component, call `useGetManyOuterCartonParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOuterCartonParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOuterCartonParamQuery();
 */
export function useGetManyOuterCartonParamQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyOuterCartonParamQuery, schema.GetManyOuterCartonParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOuterCartonParamQuery, schema.GetManyOuterCartonParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOuterCartonParamQuery, schema.GetManyOuterCartonParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOuterCartonParamQuery, schema.GetManyOuterCartonParamQueryVariables>(GetManyOuterCartonParamDocument, {}, options);
}
export type GetManyOuterCartonParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOuterCartonParamQuery, schema.GetManyOuterCartonParamQueryVariables>;
export const GetOneOuterCartonParamDocument = gql`
    query GetOneOuterCartonParam($id: ID!) {
  outerCartonParam(id: $id) {
    ...OuterCartonParamFragment
  }
}
    ${OuterCartonParamFragmentFragmentDoc}`;

/**
 * __useGetOneOuterCartonParamQuery__
 *
 * To run a query within a Vue component, call `useGetOneOuterCartonParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneOuterCartonParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneOuterCartonParamQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneOuterCartonParamQuery(variables: schema.GetOneOuterCartonParamQueryVariables | VueCompositionApi.Ref<schema.GetOneOuterCartonParamQueryVariables> | ReactiveFunction<schema.GetOneOuterCartonParamQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneOuterCartonParamQuery, schema.GetOneOuterCartonParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneOuterCartonParamQuery, schema.GetOneOuterCartonParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneOuterCartonParamQuery, schema.GetOneOuterCartonParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneOuterCartonParamQuery, schema.GetOneOuterCartonParamQueryVariables>(GetOneOuterCartonParamDocument, variables, options);
}
export type GetOneOuterCartonParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneOuterCartonParamQuery, schema.GetOneOuterCartonParamQueryVariables>;
export const GetManyPriceLabelParamDocument = gql`
    query GetManyPriceLabelParam {
  priceLabelParams {
    ...PriceLabelParamFragment
  }
}
    ${PriceLabelParamFragmentFragmentDoc}`;

/**
 * __useGetManyPriceLabelParamQuery__
 *
 * To run a query within a Vue component, call `useGetManyPriceLabelParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyPriceLabelParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyPriceLabelParamQuery();
 */
export function useGetManyPriceLabelParamQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyPriceLabelParamQuery, schema.GetManyPriceLabelParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyPriceLabelParamQuery, schema.GetManyPriceLabelParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyPriceLabelParamQuery, schema.GetManyPriceLabelParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyPriceLabelParamQuery, schema.GetManyPriceLabelParamQueryVariables>(GetManyPriceLabelParamDocument, {}, options);
}
export type GetManyPriceLabelParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyPriceLabelParamQuery, schema.GetManyPriceLabelParamQueryVariables>;
export const GetOnePriceLabelParamDocument = gql`
    query GetOnePriceLabelParam($id: ID!) {
  priceLabelParam(id: $id) {
    ...PriceLabelParamFragment
  }
}
    ${PriceLabelParamFragmentFragmentDoc}`;

/**
 * __useGetOnePriceLabelParamQuery__
 *
 * To run a query within a Vue component, call `useGetOnePriceLabelParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnePriceLabelParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOnePriceLabelParamQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOnePriceLabelParamQuery(variables: schema.GetOnePriceLabelParamQueryVariables | VueCompositionApi.Ref<schema.GetOnePriceLabelParamQueryVariables> | ReactiveFunction<schema.GetOnePriceLabelParamQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOnePriceLabelParamQuery, schema.GetOnePriceLabelParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOnePriceLabelParamQuery, schema.GetOnePriceLabelParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOnePriceLabelParamQuery, schema.GetOnePriceLabelParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOnePriceLabelParamQuery, schema.GetOnePriceLabelParamQueryVariables>(GetOnePriceLabelParamDocument, variables, options);
}
export type GetOnePriceLabelParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOnePriceLabelParamQuery, schema.GetOnePriceLabelParamQueryVariables>;
export const GetManyProductDocument = gql`
    query GetManyProduct($name: String, $clientUserGroup: ID, $productTagIds: [ID!]) {
  products(
    name: $name
    clientUserGroup: $clientUserGroup
    productTagIds: $productTagIds
  ) {
    ...ProductFragment
    files {
      ...ProductFileFragment
    }
  }
}
    ${ProductFragmentFragmentDoc}
${ProductFileFragmentFragmentDoc}`;

/**
 * __useGetManyProductQuery__
 *
 * To run a query within a Vue component, call `useGetManyProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyProductQuery({
 *   name: // value for 'name'
 *   clientUserGroup: // value for 'clientUserGroup'
 *   productTagIds: // value for 'productTagIds'
 * });
 */
export function useGetManyProductQuery(variables: schema.GetManyProductQueryVariables | VueCompositionApi.Ref<schema.GetManyProductQueryVariables> | ReactiveFunction<schema.GetManyProductQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyProductQuery, schema.GetManyProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyProductQuery, schema.GetManyProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyProductQuery, schema.GetManyProductQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyProductQuery, schema.GetManyProductQueryVariables>(GetManyProductDocument, variables, options);
}
export type GetManyProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyProductQuery, schema.GetManyProductQueryVariables>;
export const GetOneProductDocument = gql`
    query GetOneProduct($id: ID!) {
  product(id: $id) {
    ...ProductFragment
    files {
      ...ProductFileFragment
    }
    prices {
      ...ProductPriceFragment
    }
  }
}
    ${ProductFragmentFragmentDoc}
${ProductFileFragmentFragmentDoc}
${ProductPriceFragmentFragmentDoc}`;

/**
 * __useGetOneProductQuery__
 *
 * To run a query within a Vue component, call `useGetOneProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneProductQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneProductQuery(variables: schema.GetOneProductQueryVariables | VueCompositionApi.Ref<schema.GetOneProductQueryVariables> | ReactiveFunction<schema.GetOneProductQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneProductQuery, schema.GetOneProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>(GetOneProductDocument, variables, options);
}
export type GetOneProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>;
export const GetManyProductCategoryDocument = gql`
    query GetManyProductCategory {
  productCategories {
    ...ProductCategoryFragment
  }
}
    ${ProductCategoryFragmentFragmentDoc}`;

/**
 * __useGetManyProductCategoryQuery__
 *
 * To run a query within a Vue component, call `useGetManyProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyProductCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyProductCategoryQuery();
 */
export function useGetManyProductCategoryQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyProductCategoryQuery, schema.GetManyProductCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyProductCategoryQuery, schema.GetManyProductCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyProductCategoryQuery, schema.GetManyProductCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyProductCategoryQuery, schema.GetManyProductCategoryQueryVariables>(GetManyProductCategoryDocument, {}, options);
}
export type GetManyProductCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyProductCategoryQuery, schema.GetManyProductCategoryQueryVariables>;
export const GetOneProductPriceDocument = gql`
    query GetOneProductPrice($id: ID!) {
  productPrice(id: $id) {
    ...ProductPriceFragment
    product {
      ...ProductFragment
    }
  }
}
    ${ProductPriceFragmentFragmentDoc}
${ProductFragmentFragmentDoc}`;

/**
 * __useGetOneProductPriceQuery__
 *
 * To run a query within a Vue component, call `useGetOneProductPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneProductPriceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneProductPriceQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneProductPriceQuery(variables: schema.GetOneProductPriceQueryVariables | VueCompositionApi.Ref<schema.GetOneProductPriceQueryVariables> | ReactiveFunction<schema.GetOneProductPriceQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>(GetOneProductPriceDocument, variables, options);
}
export type GetOneProductPriceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>;
export const GetManyProductTagDocument = gql`
    query GetManyProductTag {
  productTags {
    ...ProductTagFragment
  }
}
    ${ProductTagFragmentFragmentDoc}`;

/**
 * __useGetManyProductTagQuery__
 *
 * To run a query within a Vue component, call `useGetManyProductTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyProductTagQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyProductTagQuery();
 */
export function useGetManyProductTagQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyProductTagQuery, schema.GetManyProductTagQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyProductTagQuery, schema.GetManyProductTagQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyProductTagQuery, schema.GetManyProductTagQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyProductTagQuery, schema.GetManyProductTagQueryVariables>(GetManyProductTagDocument, {}, options);
}
export type GetManyProductTagQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyProductTagQuery, schema.GetManyProductTagQueryVariables>;
export const GetManyTypesettingParamDocument = gql`
    query GetManyTypesettingParam {
  typesettingParams {
    ...TypesettingParamFragment
  }
}
    ${TypesettingParamFragmentFragmentDoc}`;

/**
 * __useGetManyTypesettingParamQuery__
 *
 * To run a query within a Vue component, call `useGetManyTypesettingParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyTypesettingParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyTypesettingParamQuery();
 */
export function useGetManyTypesettingParamQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyTypesettingParamQuery, schema.GetManyTypesettingParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyTypesettingParamQuery, schema.GetManyTypesettingParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyTypesettingParamQuery, schema.GetManyTypesettingParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyTypesettingParamQuery, schema.GetManyTypesettingParamQueryVariables>(GetManyTypesettingParamDocument, {}, options);
}
export type GetManyTypesettingParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyTypesettingParamQuery, schema.GetManyTypesettingParamQueryVariables>;
export const GetOneTypesettingParamDocument = gql`
    query GetOneTypesettingParam($id: ID!) {
  typesettingParam(id: $id) {
    ...TypesettingParamFragment
  }
}
    ${TypesettingParamFragmentFragmentDoc}`;

/**
 * __useGetOneTypesettingParamQuery__
 *
 * To run a query within a Vue component, call `useGetOneTypesettingParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTypesettingParamQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneTypesettingParamQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneTypesettingParamQuery(variables: schema.GetOneTypesettingParamQueryVariables | VueCompositionApi.Ref<schema.GetOneTypesettingParamQueryVariables> | ReactiveFunction<schema.GetOneTypesettingParamQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneTypesettingParamQuery, schema.GetOneTypesettingParamQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneTypesettingParamQuery, schema.GetOneTypesettingParamQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneTypesettingParamQuery, schema.GetOneTypesettingParamQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneTypesettingParamQuery, schema.GetOneTypesettingParamQueryVariables>(GetOneTypesettingParamDocument, variables, options);
}
export type GetOneTypesettingParamQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneTypesettingParamQuery, schema.GetOneTypesettingParamQueryVariables>;