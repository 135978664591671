
import { defineComponent } from "vue";
import { formatCurrency, parseCurrency } from "../../core/filters";
type Props = {
  onlyInteger: boolean;
};

export default defineComponent({
  inheritAttrs: false,
  props: {
    onlyInteger: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: Props) {
    let endsWithCommna = false;

    const formatter = (value: number | string): string => {
      const formated = formatCurrency(value);
      if (endsWithCommna) {
        return `${formated}.`;
      } else {
        return formated;
      }
    };

    const parser = (value: string): number => {
      endsWithCommna = props.onlyInteger ? false : value.endsWith(".");
      return parseCurrency(value);
    };

    return {
      formatter,
      parser,
    };
  },
});
