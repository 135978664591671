import { Attr, Attrs } from "./attrs";

import { ta, t } from "@/shared/providors/i18n";

export function typeConverter(type: string): any {
  switch (type) {
    case "currency":
    case "currencyInteger":
    case "percent":
    case "percentInteger":
      return "integer";
    case "zipCode":
      return "string";
    case "date":
    case "datetime":
    case "timestamp":
      return "date";
    case "id":
    case "text":
    case "email":
    case "password":
      return "string";
    case "customKind":
    case "model":
      return "object";
    case "enum":
      return "string";
    case "float":
      return "any"; // floatの場合、integerを許容する必要があるため
    default:
      return type;
  }
}

export function buildAttrRule<T>(attr: Attr<T>): any[] {
  const rules = [];

  if (attr.required) {
    rules.push({
      type: typeConverter(attr.type),
      required: attr.required,
      message: `${ta(attr.key)}${t("errors.messages.required")}`,
      trigger: "blur",
    });
  }

  if (attr.type == "integer" || attr.type == "float") {
    if ("min" in attr) {
      rules.push({
        type: attr.type,
        min: attr.min,
        message: `${ta(attr.key)}${t(
          "errors.messages.greater_than_or_equal_to",
          { count: attr.min }
        )}`,
        trigger: "blur",
      });
    }
    if ("max" in attr) {
      rules.push({
        type: attr.type,
        max: attr.max,
        message: `${ta(attr.key)}${t("errors.messages.less_than_or_equal_to", {
          count: attr.max,
        })}`,
        trigger: "blur",
      });
    }
  } else if (
    attr.type == "string" ||
    attr.type == "text" ||
    attr.type == undefined
  ) {
    if ("min" in attr) {
      rules.push({
        type: "string",
        min: attr.min,
        message: `${ta(attr.key)}${t("errors.messages.too_short", {
          count: attr.min,
        })}`,
        trigger: "blur",
      });
    }
    if ("max" in attr) {
      rules.push({
        type: "string",
        max: attr.max,
        message: `${ta(attr.key)}${t("errors.messages.too_long", {
          count: attr.max,
        })}`,
        trigger: "blur",
      });
    }
    if ("len" in attr) {
      rules.push({
        type: "string",
        len: attr.len,
        message: `${ta(attr.key)}${t("errors.messages.wrong_length", {
          count: attr.len,
        })}`,
        trigger: "blur",
      });
    }
  }

  return rules;
}

export function buildRule<T>(attrs: Attrs<T>): any {
  const rules: { [key: string]: any } = {};
  attrs.forEach((attr: Attr<T>) => {
    rules[attr.key] = buildAttrRule(attr);
  });
  return rules;
}

// "errors": {
//   "format": "%{attribute}%{message}",
//   "messages": {
//     "accepted": "を受諾してください",
//     "blank": "を入力してください",
//     "confirmation": "と%{attribute}の入力が一致しません",
//     "empty": "を入力してください",
//     "equal_to": "は%{count}にしてください",
//     "even": "は偶数にしてください",
//     "exclusion": "は予約されています",
//     "greater_than": "は%{count}より大きい値にしてください",
//     "greater_than_or_equal_to": "は%{count}以上の値にしてください",
//     "inclusion": "は一覧にありません",
//     "invalid": "は不正な値です",
//     "less_than": "は%{count}より小さい値にしてください",
//     "less_than_or_equal_to": "は%{count}以下の値にしてください",
//     "model_invalid": "バリデーションに失敗しました: %{errors}",
//     "not_a_number": "は数値で入力してください",
//     "not_an_integer": "は整数で入力してください",
//     "odd": "は奇数にしてください",
//     "other_than": "は%{count}以外の値にしてください",
//     "present": "は入力しないでください",
//     "required": "を入力してください",
//     "taken": "はすでに存在します",
//     "too_long": "は%{count}文字以内で入力してください",
//     "too_short": "は%{count}文字以上で入力してください",
//     "wrong_length": "は%{count}文字で入力してください"
//   },
//   "template": {
//     "body": "次の項目を確認してください",
//     "header": {
//       "one": "%{model}にエラーが発生しました",
//       "other": "%{model}に%{count}個のエラーが発生しました"
//     }
//   }
// },
