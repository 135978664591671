import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

export const bugsnagClient = Bugsnag.start({
  apiKey: "e2e189bb2286f5739edda8d41989e6ab", // process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ["production"],
  //otherOptions: {value}
});
export const bugsnagVue = Bugsnag.getPlugin("vue");
