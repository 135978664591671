export * from "./core";
export * from "./components";
//export * from "./modules";

import { SchemaKey } from "./modules";

export const Generic = {
  install: (app: any, schema: any) => {
    app.provide(SchemaKey, schema);
  },
};

export default Generic;
