<style lang="less">
.draged-up {
  box-sizing: border-box;
  border-top: 2px solid #aaa;
}
.draged-down {
  box-sizing: border-box;
  border-bottom: 2px solid #aaa;
}
</style>

<template>
  <div v-bind="attrs" v-on="listeners" ref="imgRef">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  SetupContext,
  ref,
  onMounted,
  onUnmounted,
} from "vue";

// ======= store
type Store = {
  draggingItem: any;
  dragedItem: any;
  draggingUpper: boolean | null;
};

const store: { [key: string]: Store } = {};
function clearStore(namespace: string) {
  store[namespace] = {
    draggingItem: null,
    dragedItem: null,
    draggingUpper: null,
  };
}

function getStore(namespace: string): Store {
  if (store[namespace] == null) {
    clearStore(namespace);
  }
  return store[namespace];
}

// ======= end store

type Props = {
  draggable: boolean;
  dataSource: any;
  value: any;
  namespace: string;
};

export default defineComponent({
  props: {
    namespace: {
      type: String,
      required: false,
      default: "g",
    },
    draggable: {
      type: Boolean,
      required: false,
      default: true,
    },
    dataSource: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const imgRef = ref<HTMLImageElement>();

    const store = getStore(props.namespace);

    const craeteDragImgElement = () => {
      const dragImg = document.createElement("div");
      dragImg.textContent = props.value.name;
      dragImg.style.backgroundColor = "white";
      dragImg.style.position = "absolute";
      dragImg.style.width = "200px";
      dragImg.style.height = "57px";
      dragImg.style.padding = "20px 8px";
      dragImg.style.fontSize = "14px";
      dragImg.style.top = "0";
      dragImg.style.left = "0";
      dragImg.style.zIndex = "-1";
      return dragImg;
    };

    const dragstart = (evt: DragEvent) => {
      // Set Ghost Image
      if (evt == null) return;

      const dragImgElement = craeteDragImgElement();
      const target: HTMLElement = evt.target as HTMLElement;
      target.appendChild(dragImgElement);
      evt?.dataTransfer?.setDragImage(dragImgElement, 0, 0);
      setTimeout(() => {
        dragImgElement.remove();
      }, 100);

      store.draggingItem = props.value;
    };

    const dragenter = (evt: DragEvent) => {
      if (evt.preventDefault) evt.preventDefault();
      if (evt.stopPropagation) evt.stopPropagation();

      store.draggingUpper = null;
      store.dragedItem = props.value;
    };

    const dragover = (evt: DragEvent): boolean => {
      if (evt.preventDefault) evt.preventDefault();
      if (evt.stopPropagation) evt.stopPropagation();
      const ele: HTMLElement = evt.target as HTMLElement;
      const target = ele.closest("tr");
      if (target != null) {
        const draggingUpper = evt.offsetY < target.offsetHeight / 2;

        if (store.draggingUpper !== draggingUpper) {
          store.draggingUpper = draggingUpper;
          if (store.draggingUpper) {
            target.classList.remove("draged-down");
            target.classList.add("draged-up");
          } else {
            target.classList.remove("draged-up");
            target.classList.add("draged-down");
          }
        }
      }

      return false;
    };

    const dragleave = (evt: DragEvent): void => {
      if (evt.preventDefault) evt.preventDefault();
      if (evt.stopPropagation) evt.stopPropagation();
      const ele: HTMLElement = evt.target as HTMLElement;

      if (ele.tagName === "TD") {
        const target = ele.closest("TR");
        if (target != null) {
          target.classList.remove("draged-up");
          target.classList.remove("draged-down");
        }
      }
    };

    const dragend = (): void => {
      context.emit(
        "dragged",
        store.draggingItem,
        store.dragedItem,
        store.draggingUpper,
        props.dataSource
      );
    };

    const attrs = {
      draggable: props.draggable,
      style: {
        cursor: "grab",
      },
    };

    const listeners = {
      dragstart,
      dragend,
    };

    onMounted(() => {
      clearStore(props.namespace);
      if (imgRef.value) {
        const ele = imgRef.value;

        const tableEle: HTMLElement = ele.closest("TABLE") as HTMLElement;
        if (tableEle) {
          tableEle.style.borderCollapse = "collapse";
        }

        const containerEle = ele.closest("TR");
        if (containerEle) {
          containerEle.addEventListener(
            "dragenter",
            { handleEvent: dragenter },
            true
          );
          containerEle.addEventListener(
            "dragover",
            { handleEvent: dragover },
            true
          );
          containerEle.addEventListener(
            "dragleave",
            { handleEvent: dragleave },
            true
          );
        }
      }
    });

    onUnmounted(() => {
      clearStore(props.namespace);
      if (imgRef.value) {
        const ele = imgRef.value;
        const containerEle = ele.closest("tr");
        if (containerEle) {
          containerEle.addEventListener("dragenter", {
            handleEvent: dragenter,
          });
          containerEle.addEventListener("dragover", { handleEvent: dragover });
          containerEle.addEventListener("dragleave", {
            handleEvent: dragleave,
          });
        }
      }
    });

    return {
      attrs,
      listeners,
      imgRef,
    };
  },
});
</script>
